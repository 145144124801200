import { OLD_RESERVATIONS_QUERY_PARAM } from 'frontend-container/components/Menu/components/GlobalSearchModal/configConstants';
import { GlobalSearchMenuElementQueryParams } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';

import { buildFIQLFilter, FIQLOperators } from '@ac/library-api';
import { serializeToUrlSearchString } from '@ac/library-utils/dist/utils';

export const getLinkForSearchByClick = (
  link: string,
  queryParams: GlobalSearchMenuElementQueryParams,
  searchValue: string | undefined
): string | undefined => {
  if (!queryParams) {
    return;
  }

  if (queryParams.isReservationCustomParam && queryParams.filterKey) {
    const query = encodeURIComponent(
      JSON.stringify({ [queryParams.filterKey]: searchValue })
    );

    return `${link}?${OLD_RESERVATIONS_QUERY_PARAM}=${query}`;
  }

  if (queryParams.filterKey) {
    const filter = queryParams.operator
      ? buildFIQLFilter(queryParams.filterKey, FIQLOperators.equal, searchValue)
      : {
          [queryParams.filterKey]: searchValue,
        };
    const serializedFilter = serializeToUrlSearchString({
      filter,
    });

    const filterWithoutUnderscore = queryParams?.underscore
      ? serializedFilter
      : serializedFilter.replace(/_/g, '');

    return `${link}?${filterWithoutUnderscore}`;
  }

  if (queryParams.queryKey) {
    const query = serializeToUrlSearchString({
      [queryParams.queryKey]: searchValue,
    });

    const adjustedQuery = queryParams.underscore
      ? query
      : query.replace(/_/g, '');

    return `${link}?${adjustedQuery}`;
  }

  return;
};
