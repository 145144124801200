import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalSearchModalListItemText } from 'frontend-container/components/Menu/components/GlobalSearchModal/components/GlobalSearchModalListItemText';
import { GlobalSearchModalShortcut } from 'frontend-container/components/Menu/components/GlobalSearchModal/components/GlobalSearchModalShortcut';
import { GLOBAL_SEARCH_MODAL_ID } from 'frontend-container/components/Menu/components/GlobalSearchModal/configConstants';
import {
  GlobalSearchMenuElementItem,
  ItemType,
} from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { parseMacShortcutsToViewMode } from 'frontend-container/components/Menu/utils/parseMacShortcusToViewMode';
import { isMac } from 'frontend-container/utils/isMac';

import { LoginService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';
import { useSharedTooltipPresenter } from '@ac/react-infrastructure';
import { AlignItems, Color, IconName } from '@ac/web-components';
import { JSX as WbcJSX } from '@ac/web-components/dist/types/components';
import { HTMLStencilElement } from '@ac/web-components/dist/types/stencil-public-runtime';

interface Props {
  item: GlobalSearchMenuElementItem;
  type: ItemType;
  highlightedText?: string;
  isFavourite?: boolean;
  isHighlighted?: boolean;
  onClickCallback?: () => void;
  onFavoriteClickCallback?: (isFavourite: boolean) => void;
}

export const GlobalSearchModalListItem = ({
  item,
  type,
  highlightedText,
  isFavourite,
  isHighlighted,
  onClickCallback,
  onFavoriteClickCallback,
}: Props): JSX.Element | null => {
  const listItemRef = useRef<(WbcJSX.AcFlex & HTMLStencilElement) | undefined>(
    undefined
  );
  const { t } = useTranslation();

  const { show } = useSharedTooltipPresenter({
    attachTo: `#${GLOBAL_SEARCH_MODAL_ID}`,
  });

  useLayoutEffect(() => {
    if (isHighlighted && listItemRef.current) {
      listItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [isHighlighted]);

  const trimBackSlashAndColonFromId = item.id
    .split('/')
    .join('')
    .split(':')
    .join('')
    .split('.')
    .join('');

  const iconId = `id-icon-${trimBackSlashAndColonFromId}-${type}`;
  const favoriteIconId = `id-favorite-${trimBackSlashAndColonFromId}-${type}`;

  const shortcut = isMac
    ? parseMacShortcutsToViewMode(item.keyboardShortcutMac)
    : item.keyboardShortcutWindows;

  const onMouseEnterHandler = (): void => {
    if (!item.parentName) {
      return;
    }

    show({
      text: t(item.parentName),
      for: `#${iconId}`,
    });
  };

  return (
    <ac-flex
      id={item.id}
      class="global-search-modal-list-item overflow-x-hidden"
      dynamicClass={
        isHighlighted ? 'global-search-modal-list-item-highlighted' : undefined
      }
      alignItems={AlignItems.center}
      ref={(ref): (WbcJSX.AcFlex & HTMLStencilElement) | undefined =>
        (listItemRef.current = ref)
      }
      onClick={(): void => onClickCallback?.()}
    >
      {item.icon && (
        <ac-icon
          icon={item.icon}
          color={Color.foregroundMinimal}
          class="ac-margin-inline-end-lg"
          id={iconId}
          onMouseEnter={onMouseEnterHandler}
        />
      )}
      <GlobalSearchModalListItemText
        item={item}
        highlightedText={highlightedText}
      />
      {isDefined(isFavourite) && !LoginService.isSuperUser() && (
        <ac-icon
          id={favoriteIconId}
          class="global-search-modal-list-item-favorite-icon"
          color={isFavourite ? Color.foregroundSubtle : Color.foregroundMinimal}
          icon={isFavourite ? IconName.star : IconName.starOutline}
          onMouseEnter={(): void => {
            show({
              text: t(
                isFavourite
                  ? 'MENU.GLOBAL_SEARCH.REMOVE_FROM_FAVORITES'
                  : 'MENU.GLOBAL_SEARCH.ADD_TO_FAVORITES'
              ),
              for: `#${favoriteIconId}`,
            });
          }}
          onClick={(event): void => {
            show({
              text: '',
              for: `#${favoriteIconId}`,
            });
            event.stopPropagation();
            onFavoriteClickCallback?.(!isFavourite);
          }}
        />
      )}
      {shortcut && <GlobalSearchModalShortcut text={shortcut} />}
    </ac-flex>
  );
};
