import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { getActivityReservationsMenu } from 'frontend-container/components/Menu/configuration/activityReservations';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/centralAvailabilityInquiry';
import { croStructureMenu } from 'frontend-container/components/Menu/configuration/croStructure';
import { getGolfMenu } from 'frontend-container/components/Menu/configuration/golf';
import { inquiryMenu } from 'frontend-container/components/Menu/configuration/inquiry';
import {
  configurationProfileCentersMenu,
  profileCenterMenu,
} from 'frontend-container/components/Menu/configuration/profileCenters';
import { propertyUsers } from 'frontend-container/components/Menu/configuration/propertyUsers';
import { ratesMenu } from 'frontend-container/components/Menu/configuration/rates';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/reservationsLookup';
import { resourceManagementMenu } from 'frontend-container/components/Menu/configuration/resourceManagement';
import { salesActivitiesMenu } from 'frontend-container/components/Menu/configuration/salesActivities';
import { getReportingMenuItems } from 'frontend-container/components/Menu/configuration/utils/getReportingMenuItems';
import { workflowsMenu } from 'frontend-container/components/Menu/configuration/workflows';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';

import { accountsReceivableMenu } from './accountsReceivable';
import { applicationDashboardsMenu } from './application';
import { cashieringMenu } from './cashiering';
import { changelogMenu } from './changelog';
import { commissionsMenu } from './commissions';
import {
  configurationMenu,
  getConfigurationMenuInMainApplication,
} from './configuration';
import { customersMenuFull, customersMenuReadOnlyMode } from './customers';
import { endOfDayMenu } from './endOfDay';
import { frontdeskMenu } from './frontdesk';
import { getGroupsMenu } from './groups';
import { housekeepingMenu } from './housekeeping';
import {
  integrationsMenu,
  integrationsMenuForSystemUser,
} from './integrations';
import { meetingsAndEventsMenu } from './meetingsAndEvents';
import { organizationStructureMenu } from './organizationStructure';
import { profilesMenu } from './profiles';
import { reportsMenu } from './reports';
import { reservationsMenu } from './reservations';
import { getSystemUsersMenu } from './systemUsers';
import { taskManagementMenu } from './taskManagement';
import { usersMenu } from './users';

export const menuItemHeightInPx = 42;
const menuHeaderHeightInPx = 32;

const fillShiftForMenuElement = (menuElement: MenuElement): MenuElement => {
  if (!menuElement.items?.length) {
    menuElement.items = [];
  }

  return {
    ...menuElement,
    shift:
      (menuItemHeightInPx * menuElement.items.length + menuHeaderHeightInPx) /
        2 -
      menuHeaderHeightInPx / 2,
  };
};

export const getFullMenu = (): MenuElement[] => [
  // Change in order may break DocumentTitle service.
  getSystemUsersMenu(),
  customersMenuFull,
  integrationsMenuForSystemUser,
  organizationStructureMenu,
  croStructureMenu,
  reservationsLookupMenu,
  profileCenterMenu,
  centralAvailabilityInquiryMenu,
  usersMenu,
  integrationsMenu,
  ...getMainApplicationMenu(),
  applicationDashboardsMenu,
  getConfigurationMenuInMainApplication(),
  changelogMenu,
];

export const getMainApplicationMenu = (): MenuElement[] =>
  [
    inquiryMenu,
    profilesMenu,
    reservationsMenu,
    getGolfMenu(),
    getActivityReservationsMenu(),
    resourceManagementMenu,
    ratesMenu,
    getGroupsMenu(),
    frontdeskMenu,
    cashieringMenu,
    housekeepingMenu,
    taskManagementMenu,
    {
      ...reportsMenu,
      items: [...reportsMenu.items, ...getReportingMenuItems()],
    },
    endOfDayMenu,
    meetingsAndEventsMenu,
    salesActivitiesMenu,
    accountsReceivableMenu,
    commissionsMenu,
    integrationsMenu,
    changelogMenu,
    propertyUsers,
  ].map(fillShiftForMenuElement);

export const getMainApplicationMenuV2 = (): MenuElement[] =>
  [
    inquiryMenu,
    reservationsMenu,
    frontdeskMenu,
    cashieringMenu,
    profilesMenu,
    meetingsAndEventsMenu,
    getGroupsMenu(),
    getActivityReservationsMenu(),
    getGolfMenu(),
    housekeepingMenu,
    ratesMenu,
    resourceManagementMenu,
    commissionsMenu,
    accountsReceivableMenu,
    taskManagementMenu,
    salesActivitiesMenu,
    propertyUsers,

    {
      ...reportsMenu,
      items: [...reportsMenu.items, ...getReportingMenuItems()],
    },

    changelogMenu,
    integrationsMenu,
    endOfDayMenu,
    [
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
    ],
    getSystemUsersMenu(),
    integrationsMenuForSystemUser,
  ].map(fillShiftForMenuElement);

export const getAllowedMainApplicationMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] =>
  getIsMenuV2Enabled()
    ? getAllowedMenuItems(getMainApplicationMenuV2(), configuration)
    : getAllowedMenuItems(getMainApplicationMenu(), configuration);

export const getAllowedConfigurationMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  let menuElements: MenuElement[];

  if (LoginService.isSuperUser()) {
    menuElements = [
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
      getSystemUsersMenu(),
      changelogMenu,
      integrationsMenuForSystemUser,
    ];
  } else {
    menuElements = [
      organizationStructureMenu,
      configurationProfileCentersMenu,
      croStructureMenu,
      usersMenu,
      integrationsMenu,
    ];
  }

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

export const getAllowedWorkflowsMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [
    workflowsMenu,
    changelogMenu,
    configurationMenu,
    applicationDashboardsMenu,
  ];

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

export const getAllowedChangelogMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  let menuElements: MenuElement[];

  if (LoginService.isSuperUser()) {
    menuElements = [
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
      getSystemUsersMenu(),
      changelogMenu,
      integrationsMenuForSystemUser,
    ];
  } else {
    menuElements = [changelogMenu];
  }

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

export const getAllowedCentralReservationOfficeMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [centralAvailabilityInquiryMenu, reservationsLookupMenu];

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

export const getAllowedProfileCenterMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [profileCenterMenu];

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

export const getAllowedIntegrationsMenuElementsForSystemUser = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [
    SessionService.getReadOnlyMode()
      ? customersMenuReadOnlyMode
      : customersMenuFull,
    getSystemUsersMenu(),
    changelogMenu,
    integrationsMenuForSystemUser,
  ];

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};
