import { isConfigurationContextVisible } from 'frontend-container/components/Menu/components/ConfigurationContext/isConfigurationContextVisible';
import { isCroContextVisible } from 'frontend-container/components/Menu/components/CroContext';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';

export enum ContextOption {
  property = 'property',
  cro = 'cro',
  profileCenter = 'profile-center',
  configuration = 'configuration',
}

export const getCurrentContextOption = (): ContextOption | undefined => {
  if (isPropertyContextVisible()) {
    return ContextOption.property;
  }

  if (isCroContextVisible()) {
    return ContextOption.cro;
  }

  if (isProfileCentersContextVisible()) {
    return ContextOption.profileCenter;
  }

  if (isConfigurationContextVisible()) {
    return ContextOption.configuration;
  }

  return undefined;
};
