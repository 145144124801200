type Region = 'cn' | 'global';

type RegionConfiguration = {
  [Key in Region]: string;
};

const regions: RegionConfiguration = {
  cn: 'https://support.shijigroup.cn/',
  global: 'https://support-intl.shijicloud.com/',
};

export const buildRemoteSupportApplicationUrl = (
  regionCode: Region,
  sessionKey: string
): string => `${regions[regionCode]}api/start_session?short_key=${sessionKey}`;
