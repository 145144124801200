import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { hasAnyCroContextGuard } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';

import {
  centralReservationOfficePermissionKeys,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const CENTRAL_AVAILABILITY_MENU_ID = 'menu-central-availability-inquiry';

export const centralAvailabilityInquiryMenu: MenuElement = {
  translation: 'MENU.CENTRAL_AVAILABILITY_INQUIRY.TITLE',
  icon: IconName.calendarPeriod,
  id: CENTRAL_AVAILABILITY_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.CentralReservationOffice,
      source: AccessSource.CentralReservationOffice,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.centralReservationOffice}/central-availability-inquiry`,
      code: 'CentralAvailabilityInquiry',
      id: `${CENTRAL_AVAILABILITY_MENU_ID}-dashboard`,
      aliases: [acConfig.newFrontendUrls.centralReservationOffice],
      translation: 'MENU.CENTRAL_AVAILABILITY_INQUIRY.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: centralReservationOfficePermissionKeys.useCentralAvailabilityInquiry,
            source: AccessSource.CentralReservationOffice,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      allowedWhen: hasAnyCroContextGuard,
    },
  ],
};
