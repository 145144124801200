import { useState } from 'react';
import { runRemoteSupportSession } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/commands/runRemoteSupportSession';
import { useRemoteSupportTranslation } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/translation';

import {
  AlignItems,
  FlexDirection,
  FlexGap,
  JustifyContent,
  Type,
} from '@ac/web-components';

export const RemoteSupportSectionContent = (): JSX.Element => {
  const { t } = useRemoteSupportTranslation();
  const [sessionKey, setSessionKey] = useState<string | undefined>();

  const startRemoteSession = (): void => {
    if (!sessionKey) {
      return;
    }

    runRemoteSupportSession(sessionKey);
    setSessionKey(undefined);
  };

  const isValidSessionKey = (): boolean =>
    !!sessionKey && sessionKey.length > 3;

  return (
    <ac-flex
      class="ac-padding-vertical-lg"
      direction={FlexDirection.row}
      columnGap={FlexGap.md}
      justifyContent={JustifyContent.center}
      alignItems={AlignItems.center}
    >
      <ac-field
        type={Type.number}
        label={t('SESSION_KEY')}
        value={sessionKey}
        onKeyUpCallback={setSessionKey}
        data-test-selector="remote-support-session-key"
      />
      <ac-button
        onClick={startRemoteSession}
        disabled={!isValidSessionKey()}
        data-test-selector="remote-support-session-join-button"
      >
        <ac-button-content text={t('START_SESSION')} />
      </ac-button>
    </ac-flex>
  );
};
