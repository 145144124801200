import { PureComponent } from 'react';
import { AutomaticLogout } from 'frontend-container/components/AutomaticLogout/AutomaticLogout';
import { ContextHelpSidePanel } from 'frontend-container/components/ContextHelp/ContextHelpSidePanel/ContextHelpSidePanel';
import { pathname404 } from 'frontend-container/components/Errors/Error404';
import { Branding } from 'frontend-container/components/Menu/components/Branding/Branding';
import { DocumentTitle } from 'frontend-container/components/Menu/components/DocumentTitle';
import { UnsavedChangesModal } from 'frontend-container/components/Menu/components/UnsavedChangesModal/UnsavedChangesModal';
import { Menu } from 'frontend-container/components/Menu/Menu';
import { shouldUseDarkTheme } from 'frontend-container/components/Menu/service';
import { FavoriteListContextProvider } from 'frontend-container/components/Menu/store/favorites/Provider';
import { ContextsContextProvider } from 'frontend-container/components/Menu/store/Provider';
import { RefreshModal } from 'frontend-container/components/modals/refreshModal/RefreshModal';
import { MultiRegionErrorHandler } from 'frontend-container/components/MultiRegionError/MultiRegionErrorHandler';
import { Panels } from 'frontend-container/components/panels/Panels';
import { PanelPresenterProviders } from 'frontend-container/components/panels/Providers';
import { SessionDataSnapshotUpdater } from 'frontend-container/components/SessionData/SessionDataSnapshotUpdater';
import { Toast } from 'frontend-container/components/Toast';
import { TokenExpirationGuard } from 'frontend-container/components/TokenExpirationGuard/TokenExpirationGuard';
import { replaceWithPage } from 'frontend-container/shared/navigation/replaceWithPage';
import { isLoginUrl, isMainUrl } from 'frontend-container/utils/location';
import { checkActivityFunctions } from 'single-spa';

import {
  createProvider,
  FrontendCommunicationContextProvider,
  PropsWithChildren,
  ProviderComposer,
  RoutingContextProvider,
  SharedInfotipProvider,
  SharedTooltipProvider,
  ViewportContextProvider,
  withTranslationsReady,
} from '@ac/react-infrastructure';

import './App.scss';
import './AppDarkTheme.scss';
import './AppMediaPrint.scss';

const providers = [
  createProvider({ component: FrontendCommunicationContextProvider }),
  createProvider({ component: PanelPresenterProviders }),
  createProvider({ component: SharedInfotipProvider }),
  createProvider({ component: SharedTooltipProvider }),
  createProvider({ component: RoutingContextProvider }),
  createProvider({ component: ViewportContextProvider }),
];

const AppProviders = (props: PropsWithChildren): JSX.Element => (
  <ProviderComposer providers={providers}>{props.children}</ProviderComposer>
);

class App extends PureComponent {
  public componentDidMount(): void {
    const appsThatShouldBeActive = checkActivityFunctions(window.location);
    if (!appsThatShouldBeActive.length && !isMainUrl() && !isLoginUrl()) {
      // todo: temporary console use, remove after confirmation of problem
      // eslint-disable-next-line no-console
      console.error(
        'WRONG LOCATION: ',
        window.location.pathname,
        appsThatShouldBeActive
      );
      replaceWithPage(pathname404);

      return;
    }

    const useDarkTheme = shouldUseDarkTheme();

    document.documentElement.style.setProperty('--menu-width', '0px');

    if (useDarkTheme) {
      document.body.classList.add('main-app-dark');
    }
  }

  public render(): JSX.Element | null {
    return (
      <AppProviders>
        <ContextsContextProvider>
          <FavoriteListContextProvider>
            <Menu />
          </FavoriteListContextProvider>
        </ContextsContextProvider>
        <TokenExpirationGuard />
        <RefreshModal />
        <DocumentTitle />
        <Branding />
        <SessionDataSnapshotUpdater />
        <MultiRegionErrorHandler />
        <AutomaticLogout />
        <ContextHelpSidePanel />
        <UnsavedChangesModal />
        <Toast />

        <Panels />
      </AppProviders>
    );
  }
}

const AppWithTranslationsReady = withTranslationsReady(App);

export { AppWithTranslationsReady as App };
