import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';

export const getAllowedSearchByItems = (
  searchByItems: GlobalSearchMenuElementItem[],
  allowedItems: GlobalSearchMenuElementItem[]
): GlobalSearchMenuElementItem[] => {
  return searchByItems.filter((searchByItem) =>
    allowedItems.some((allowedItem) => {
      return allowedItem.link === searchByItem.link;
    })
  );
};
