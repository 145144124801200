import {
  AlignItems,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

interface Props {
  text: string;
}

export const GlobalSearchModalListHeader = ({
  text,
}: Props): JSX.Element | null => {
  return (
    <ac-flex
      class="global-search-modal-list-header"
      alignItems={AlignItems.center}
    >
      <ac-text
        weight={TextWeight.semibold}
        color={TextColor.foregroundSubtle}
        size={TextSize.sm}
        class="width-percentage-100"
      >
        {text.toUpperCase()}
      </ac-text>
    </ac-flex>
  );
};
