import React, { FC, LegacyRef } from 'react';
import { MenuElementItem } from 'frontend-container/components/Menu/types';

import { Target } from '@ac/web-components';

import './MenuLinks.scss';

interface Props {
  wrapperClasses: string;
  item: MenuElementItem;
  itemRef?: LegacyRef<HTMLDivElement>;
  itemProps?: Record<string, unknown>;
}

export const ExternalMenuLink: FC<Props> = (props) => {
  const linkProps = props.itemProps ?? {};

  return (
    <div
      className={props.wrapperClasses}
      ref={props.itemRef}
      data-test-selector={props.item.id}
      {...linkProps}
    >
      <ac-hyperlink
        href={props.item.link}
        target={Target.blank}
        class="external-link"
      >
        {props.item.translation}
      </ac-hyperlink>
    </div>
  );
};
