import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { croLandingPagePathname } from 'frontend-container/components/LandingPage';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import {
  getCroContexts,
  isCroEnabled,
  redirectToPropertyOrNoPermissionsPage,
} from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { PropertySelectorReadOnly } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/PropertySelectorReadOnly';
import { isPropertySelectorReadOnlyVisible } from 'frontend-container/components/Menu/components/PropertySelectorReadOnly/visibility';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';
import { redirectOnCroSelectIfContextRegionDiffer } from 'frontend-container/utils/region/redirectOnCroSelectIfContextRegionDiffer';
import { redirectToProperRegionIfNecessary } from 'frontend-container/utils/region/redirectToProperRegionIfNecessary';
import { isEmpty } from 'lodash';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';

import { Context, ContextComponent } from '../Context';

import { useCroDateTime } from './useCroDateTime';

interface Props {
  isContextSwitchHidden?: boolean;
  triggerId?: string;
}

export const CroContext = ({
  isContextSwitchHidden,
  triggerId,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<Context | undefined>(undefined);
  const { propertyId } = useSessionContextTypeIds();
  const { t } = useTranslation();

  const initialize = useCallback(async (): Promise<void> => {
    const tenantId = LoginService.authData()?.tenantId;

    if (!tenantId) {
      throw new Error('Tenant ID is undefined');
    }

    setIsLoading(true);

    const croContexts = getCroContexts();
    const propertyContexts = getPropertyContexts();

    if (isEmpty(croContexts) || !isCroEnabled()) {
      return redirectToPropertyOrNoPermissionsPage(propertyContexts.length);
    }

    const croSelected = selectInitialContext(
      croContexts,
      SessionService.getCentralReservationOfficeId()
    );

    if (croSelected) {
      await selectUnitInBusinessContext({
        tenantId,
        centralReservationOfficeId: croSelected.id,
        propertyId,
      });
    }

    setSelected(croSelected);
    setSessionContext(croSelected);
    setIsLoading(false);

    if (croSelected) {
      redirectToProperRegionIfNecessary(croSelected);
    }
  }, [propertyId]);

  const onSelect = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSelected(context);
    setSessionContext(context);

    const isRedirectedToNewCroRegion =
      redirectOnCroSelectIfContextRegionDiffer(context);

    if (!isRedirectedToNewCroRegion && tenantId) {
      history.replaceState({}, '', croLandingPagePathname);

      void selectUnitInBusinessContext({
        tenantId,
        centralReservationOfficeId: context.id,
        propertyId,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', croLandingPagePathname);
      }
    }
  };

  useEffect(() => {
    void initialize();
  }, [initialize]);

  return isLoading ? (
    <ac-loader-covering />
  ) : (
    <>
      {isPropertySelectorReadOnlyVisible() && <PropertySelectorReadOnly />}
      <ContextComponent
        selectedContext={selected}
        onSelect={onSelect}
        useDateTime={useCroDateTime}
        dateLabel={t('MENU.CONTEXT.CRO.DATE')}
        timeLabel={t('MENU.CONTEXT.CRO.TIME')}
        isContextSwitchHidden={isContextSwitchHidden}
        triggerId={triggerId}
      />
    </>
  );
};
