import { MEGA_MENU_LEFT_ORDER } from 'frontend-container/components/Menu/components/MegaMenu/megaMenuConstants';
import { MenuElement } from 'frontend-container/components/Menu/types';

export const getSortedMenuItems = (menuItems: MenuElement[]): MenuElement[][] =>
  MEGA_MENU_LEFT_ORDER.map((orderArray) => {
    const orderedElements = orderArray
      .map((elementId) => {
        const item =
          menuItems.find((menuItem) => menuItem.id === elementId) || undefined;

        return item;
      })
      .filter((el) => !!el) as MenuElement[];

    return orderedElements || [];
  }).filter((el) => el.length);

export const getSortedMenuItemsFlat = (
  menuItems: MenuElement[]
): MenuElement[] => {
  const menuItemsSorted = getSortedMenuItems(menuItems);

  if (menuItemsSorted.length) {
    return menuItemsSorted.flat();
  }

  return menuItems;
};
