import { buildRemoteSupportApplicationUrl } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/commands/supportApplications';

import { getCurrentRegionCode } from '@ac/library-utils/dist/utils/multi-region';

export const runRemoteSupportSession = (sessionKey: string): void => {
  const isChinaRegion = getCurrentRegionCode().toLowerCase().startsWith('cn');
  const regionKey = isChinaRegion ? 'cn' : 'global';

  window.open(
    buildRemoteSupportApplicationUrl(regionKey, sessionKey),
    '_blank'
  );
};
