import { MENU_LINKS_ID } from 'frontend-container/components/Menu/constants';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';

import { IconName } from '@ac/web-components';

export const getElementsWithSeparateExternalLinks = (
  menuElements: MenuElement[]
): MenuElement[] => {
  const externalLinks: MenuElementItem[] = [];

  const mappedElements = menuElements.map((menuElement) => {
    const items = menuElement.items.filter((menuElementItem) => {
      if (menuElementItem.isExternal) {
        externalLinks.push(menuElementItem);

        return false;
      }

      return true;
    });

    return {
      ...menuElement,
      items,
    };
  });

  if (!externalLinks.length) {
    return menuElements;
  }

  return [
    ...mappedElements,
    {
      translation: 'MENU.LINKS.TITLE',
      icon: IconName.connectLink,
      id: MENU_LINKS_ID,
      items: externalLinks,
    },
  ];
};
