import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import { propertyPermissionKeys } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const endOfDayViewPermission = propertyPermissionKeys.endOfDay.view;

const END_OF_DAY_MENU_ID = 'menu-end-of-day';

export const endOfDayMenu: MenuElement = {
  translation: 'MENU.END_OF_DAY.TITLE',
  icon: IconName.audit,
  id: END_OF_DAY_MENU_ID,
  items: [
    {
      link: `${acConfig.newFrontendUrls.cashiering}/end-of-day`,
      translation: 'MENU.END_OF_DAY.ITEMS.DASHBOARD',
      id: `${END_OF_DAY_MENU_ID}-end-of-day`,
      permissionsConfiguration: {
        permissions: [
          { key: endOfDayViewPermission, source: AccessSource.Property },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}E`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}E`,
      keyCode: 69,
    },
    {
      link: `${acConfig.newFrontendUrls.cashiering}/end-of-day/logs`,
      translation: 'MENU.END_OF_DAY.ITEMS.LOGS',
      id: `${END_OF_DAY_MENU_ID}-end-of-day-logs`,
      permissionsConfiguration: {
        permissions: [
          { key: endOfDayViewPermission, source: AccessSource.Property },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}F1`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}F1`,
      keyCode: 112,
    },
  ],
};
