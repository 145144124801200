import { remoteSupportSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/manifest';
import { useRemoteSupportSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/presenter/context';
import { RemoteSupportSectionContent } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/SectionContent';

import {
  PageSectionConfiguration,
  StandardPageSection,
} from '@ac/react-infrastructure';
import { CollapsiblePanelTitleSize } from '@ac/web-components';

const sectionConfig: PageSectionConfiguration = {
  header: {
    toggleContent: {
      allowToggle: true,
    },
    textSize: CollapsiblePanelTitleSize.auto,
  },
};

export const RemoteSupportSection = (): JSX.Element | null => {
  const presenter = useRemoteSupportSectionPresenterContext();

  return (
    <StandardPageSection
      manifest={remoteSupportSectionManifest}
      presenter={presenter}
      config={sectionConfig}
    >
      <RemoteSupportSectionContent />
    </StandardPageSection>
  );
};
