import { useMemo, useState } from 'react';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';

interface Props {
  globalSearchItems: GlobalSearchMenuElementItem[];
}

export interface FilteredItems {
  filteredMenuItems: GlobalSearchMenuElementItem[];
  setFilteredItemsIds: (filteredIds: string[]) => void;
}

export const useFilteredItems = ({
  globalSearchItems,
}: Props): FilteredItems => {
  const [filteredItemsIds, setFilteredItemsIds] = useState<string[]>([]);

  const filteredMenuItems = useMemo(() => {
    if (!filteredItemsIds.length) {
      return [];
    }

    return globalSearchItems.filter((allowedItem) =>
      filteredItemsIds.includes(allowedItem.id)
    );
  }, [globalSearchItems, filteredItemsIds]);

  return { filteredMenuItems, setFilteredItemsIds };
};
