import { ReactNode, useState } from 'react';
import { Header } from 'frontend-container/components/Menu/components/Item/Header/Header';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { CONTAINER_ROOT_SELECTOR } from 'frontend-container/shared/constants';

import {
  FlexDirection,
  InfotipSlot,
  OpeningBehavior,
  Placement,
  TargetValueObject,
} from '@ac/web-components';

import './MainMenuInfotip.scss';

export interface Props {
  infotipId?: string;
  componentId: string;
  bodyId?: string;
  additionalContainerClass?: string;
  triggeringComponent: ReactNode;
  headerComponent: ReactNode;
  bodyComponent: ReactNode;
  openingBehavior?: OpeningBehavior;
}

export const MainMenuInfotip = ({
  infotipId,
  componentId,
  bodyId,
  additionalContainerClass,
  triggeringComponent,
  headerComponent,
  bodyComponent,
  openingBehavior = OpeningBehavior.onMouseOver,
}: Props): JSX.Element => {
  const [isInfotipVisible, setIsInfotipVisible] = useState<boolean>(false);
  const isMenuV2Enabled = getIsMenuV2Enabled();

  const classes = [
    isInfotipVisible ? 'main-menu-infotip-active' : '',
    additionalContainerClass,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <ac-infotip
      attachTo={CONTAINER_ROOT_SELECTOR}
      dynamicClass={classes}
      id={infotipId}
      for={`#${componentId}`}
      onOpenCallback={(): void => setIsInfotipVisible(true)}
      onCloseCallback={(): void => setIsInfotipVisible(false)}
      openingBehavior={openingBehavior}
      placement={Placement.bottomEnd}
      positionWrapperClass="menu-subitems-wrapper"
      targetValue={TargetValueObject.mainMenu}
      boundaryContainer="body"
    >
      <div id={componentId} slot={InfotipSlot.component}>
        {triggeringComponent}
      </div>
      <div
        className={`menu-subitems-container layout-direction ${
          isMenuV2Enabled ? '' : 'rounded-none'
        }`}
      >
        <Header customDisplay={headerComponent} />
        <ac-flex
          id={bodyId}
          class="main-menu-infotip-body"
          direction={FlexDirection.column}
        >
          {bodyComponent}
        </ac-flex>
      </div>
    </ac-infotip>
  );
};
