import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkstationTestSelector } from 'frontend-container/components/Menu/components/Workstation/testSelectors';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { getConfigurationEntityDescription } from 'frontend-container/utils/getConfigurationEntityDescription';
import i18next from 'i18next';

import { Device, GenericEntity } from '@ac/library-api';
import {
  AlignItems,
  ButtonPattern,
  Color,
  FlexDirection,
  IconName,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

interface Props {
  loadingWorkstationDetails: boolean;
  isUserPermittedToUseReadKeyButton: boolean;
  isReadKeyFeatureEnabled: boolean;
  onOpenReadKeyModal: () => void;
  workstation?: GenericEntity;
  keyEncoder?: Device;
  paymentDevice?: Device;
  passportScanner?: Device;
  printer?: Device;
  eRegistrationDevice?: Device;
}

export const WorkstationListHeader = ({
  loadingWorkstationDetails,
  onOpenReadKeyModal,
  isUserPermittedToUseReadKeyButton,
  isReadKeyFeatureEnabled,
  workstation,
  keyEncoder,
  paymentDevice,
  passportScanner,
  printer,
  eRegistrationDevice,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const workstationName = useMemo(() => {
    return workstation
      ? `${workstation?.code} - ${getConfigurationEntityDescription(
          workstation.descriptionMap
        )}`
      : undefined;
  }, [workstation]);

  const containerClass =
    workstation ||
    keyEncoder ||
    paymentDevice ||
    passportScanner ||
    printer ||
    eRegistrationDevice
      ? 'ac-spacing-bottom-sm width-auto'
      : 'width-auto';

  const isMenuV2Enabled = getIsMenuV2Enabled();

  return (
    <>
      <ac-flex
        direction={FlexDirection.column}
        dynamicClass={`${containerClass} container-menu-item-header ${
          isMenuV2Enabled ? ' container-menu-v2-item-header' : ''
        }`}
        data-test-selector={WorkstationTestSelector.ListDropdown}
      >
        <ac-box dynamicClass="ac-spacing-bottom-sm">
          <ac-flex alignItems={AlignItems.center}>
            {isMenuV2Enabled ? (
              <ac-box>
                <ac-flex direction={FlexDirection.column} class="ac-gap-xs">
                  <ac-text size={TextSize.sm} color={TextColor.darkGray}>
                    {t('MENU.WORKSTATION.WORKSTATION')}
                  </ac-text>
                  <ac-text>
                    {workstationName
                      ? workstationName
                      : t('MENU.WORKSTATION.WORKSTATION_NOT_SET')}
                  </ac-text>
                </ac-flex>
              </ac-box>
            ) : (
              <>
                <ac-icon icon={IconName.synchro} color={Color.black} />

                <ac-box>
                  <ac-flex direction={FlexDirection.column}>
                    <ac-text weight={TextWeight.semibold}>
                      {workstationName
                        ? workstationName
                        : t('MENU.WORKSTATION.WORKSTATION_NOT_SET')}
                    </ac-text>
                    <ac-text size={TextSize.sm}>
                      {t('MENU.WORKSTATION.WORKSTATION')}
                    </ac-text>
                  </ac-flex>
                </ac-box>
              </>
            )}
          </ac-flex>
        </ac-box>
        {isReadKeyFeatureEnabled && (
          <ac-button
            id="read-key-button"
            onClick={onOpenReadKeyModal}
            pattern={ButtonPattern.secondary}
            data-test-selector={WorkstationTestSelector.ReadyKeyModalTrigger}
            disabled={!isUserPermittedToUseReadKeyButton}
          >
            <ac-button-content text={t('MENU.WORKSTATION.READ_KEY.TITLE')} />
          </ac-button>
        )}
        {isReadKeyFeatureEnabled && !isUserPermittedToUseReadKeyButton && (
          <ac-tooltip
            for="#read-key-button"
            attachTo="#read-key-button"
            text={i18next.t('MENU.WORKSTATION.READ_KEY.NOT_PERMITTED_MESSAGE')}
          />
        )}
        {loadingWorkstationDetails && <ac-loader />}
      </ac-flex>

      {workstationName && !loadingWorkstationDetails && (
        <ac-flex
          direction={FlexDirection.column}
          class="ac-gap-sm ac-spacing-horizontal-sm"
        >
          {keyEncoder && (
            <ac-text-group label={t('MENU.WORKSTATION.KEY_ENCODER')}>
              <ac-text>{keyEncoder?.code}</ac-text>
            </ac-text-group>
          )}
          {paymentDevice && (
            <ac-text-group label={t('MENU.WORKSTATION.PAYMENT_DEVICE')}>
              <ac-text>{paymentDevice?.code}</ac-text>
            </ac-text-group>
          )}
          {passportScanner && (
            <ac-text-group label={t('MENU.WORKSTATION.PASSPORT_SCANNER')}>
              <ac-text>{passportScanner?.code}</ac-text>
            </ac-text-group>
          )}
          {printer && (
            <ac-text-group label={t('MENU.WORKSTATION.PRINTER')}>
              <ac-text>{printer?.code}</ac-text>
            </ac-text-group>
          )}
          {eRegistrationDevice && (
            <ac-text-group
              label={t('MENU.WORKSTATION.E_REGISTRATION_DEVICE')}
              disabled={!eRegistrationDevice}
            >
              <ac-text>{eRegistrationDevice?.code}</ac-text>
            </ac-text-group>
          )}
        </ac-flex>
      )}
      {}
    </>
  );
};
