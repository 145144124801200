import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { GlobalSearchModalList } from 'frontend-container/components/Menu/components/GlobalSearchModal/components/GlobalSearchModalList';
import { GlobalSearchModalShortcut } from 'frontend-container/components/Menu/components/GlobalSearchModal/components/GlobalSearchModalShortcut';
import {
  GLOBAL_SEARCH_HOTKEY_MAC,
  GLOBAL_SEARCH_HOTKEY_WINDOWS,
  GLOBAL_SEARCH_MODAL_ID,
  popularLinks,
} from 'frontend-container/components/Menu/components/GlobalSearchModal/configConstants';
import { useAllowedItems } from 'frontend-container/components/Menu/components/GlobalSearchModal/hooks/useAllowedItems';
import { useFavoriteItems } from 'frontend-container/components/Menu/components/GlobalSearchModal/hooks/useFavoriteItems';
import { useFilteredItems } from 'frontend-container/components/Menu/components/GlobalSearchModal/hooks/useFilteredItems';
import { usePopularItems } from 'frontend-container/components/Menu/components/GlobalSearchModal/hooks/usePopularItems';
import { useRecentItems } from 'frontend-container/components/Menu/components/GlobalSearchModal/hooks/useRecentItems';
import { useSearchItems } from 'frontend-container/components/Menu/components/GlobalSearchModal/hooks/useSearchItems';
import {
  GlobalSearchMenuElementItem,
  GlobalSearchMenuElementQueryParams,
} from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { getHighlightedIdOrClickedElement } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/getHighlightedIdOrClickedElement';
import { getLinkForSearchByClick } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/getLinkForSearchByClick';
import { handleMenuItemClick } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/handleMenuItemClick';
import { isItemHighlighted } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/isItemHighlighted';
import { startsWithNumber } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/startsWithNumber';
import { useFavoritesContext } from 'frontend-container/components/Menu/store/favorites/context';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { isMac } from 'frontend-container/utils/isMac';

import {
  AlignItems,
  Color,
  DividerColor,
  DividerSpacing,
  FieldPattern,
  FlexDirection,
  IconName,
  isMinimumTextToSendQuery,
} from '@ac/web-components';

interface Props {
  selectedCro: Context | undefined;
  selectedProperty: Context | undefined;
  selectedProfileCenter: Context | undefined;
  mainApplicationMenu: MenuElement[];
  onCloseAndSaveCallback: () => void;
  handleSetIsDirty: (isDirty: boolean) => void;
}

export const GlobalSearchModalContentBody = ({
  selectedCro,
  selectedProfileCenter,
  selectedProperty,
  mainApplicationMenu,
  onCloseAndSaveCallback,
  handleSetIsDirty,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { globalSearchItems } = useAllowedItems({
    selectedCro,
    selectedProfileCenter,
    selectedProperty,
    mainApplicationMenu,
  });

  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [highlightedItem, setHighlightedItem] = useState<string | undefined>(
    ''
  );
  const { recentItems, setRecentIds } = useRecentItems({ globalSearchItems });
  const { filteredMenuItems, setFilteredItemsIds } = useFilteredItems({
    globalSearchItems,
  });
  const { favoritesItemsIds, toggleFavorite } = useFavoritesContext();
  const { favoriteItems } = useFavoriteItems({
    globalSearchItems,
    favoritesItemsIds,
  });

  const { popularItems } = usePopularItems({
    globalSearchItems,
    popularLinks,
    favoritesItemsIds,
  });
  const { searchForOptions, searchForNumberOptions } = useSearchItems({
    globalSearchItems,
  });

  const handleInputRef = (el: HTMLInputElement): void => {
    setTimeout(() => el?.focus());
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return (): void => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleKeyDown = (event: KeyboardEvent): void => {
    const allItemsArray = getAllItems(searchValue);
    const result = getHighlightedIdOrClickedElement(
      highlightedItem,
      allItemsArray,
      event
    );
    if (!result) {
      return;
    }
    if (typeof result === 'string') {
      setHighlightedItem(result);

      return;
    }
    onClickHandler(result, event.shiftKey);
  };

  const onClickHandler = (
    selectedItem: GlobalSearchMenuElementItem,
    openInNewTab?: boolean
  ): void => {
    const selectedItemParams = selectedItem.queryParams;
    if (selectedItemParams?.queryKey || selectedItemParams?.filterKey) {
      onSearchByClickHandler(
        selectedItem.link,
        selectedItemParams,
        openInNewTab
      );
      onCloseAndSaveCallback();

      return;
    }

    setRecentIds(selectedItem.id);

    handleMenuItemClick(
      selectedItem.link,
      globalSearchItems,
      false,
      openInNewTab
    );
    onCloseAndSaveCallback();
  };

  const shouldReloadPage = (link: string): boolean => {
    const isOnPageAlready = window.location.href.includes(link);

    return isOnPageAlready;
  };

  const onSearchByClickHandler = (
    link: string,
    queryParams?: GlobalSearchMenuElementQueryParams,
    openInNewTab?: boolean
  ): void => {
    if (!queryParams) {
      return;
    }
    const fullLink = getLinkForSearchByClick(link, queryParams, searchValue);
    if (!fullLink) {
      return;
    }

    const allItems = getAllItems(searchValue);
    const shouldReload = shouldReloadPage(link);

    handleMenuItemClick(fullLink, allItems, true, openInNewTab, shouldReload);
    onCloseAndSaveCallback();
  };

  const getNumbersOnlySearchArray = (
    searchString: string | undefined
  ): GlobalSearchMenuElementItem[] =>
    startsWithNumber(searchString) ? searchForNumberOptions : [];

  const getAllItems = (
    searchText: string | undefined
  ): GlobalSearchMenuElementItem[] => {
    if (searchText) {
      return [
        ...filteredMenuItems,
        ...getNumbersOnlySearchArray(searchValue),
        ...searchForOptions,
      ];
    }

    return [...recentItems, ...favoriteItems, ...popularItems];
  };

  const onInputHandler = (value: string | undefined): void => {
    if (!value) {
      setFilteredItemsIds([]);
      setSearchValue(value);
      const allItemsArray = getAllItems(value);
      setHighlightedItem(allItemsArray[0]?.adjustedId ?? allItemsArray[0]?.id);
    }
    if (value && isMinimumTextToSendQuery(value)) {
      setSearchValue(value);
      const newFilteredItems = globalSearchItems.filter((allowedItem) =>
        isItemHighlighted(allowedItem.translation, value)
      );
      setFilteredItemsIds(newFilteredItems.map((el) => el?.id));
      setHighlightedItem(
        [
          ...newFilteredItems,
          ...getNumbersOnlySearchArray(value),
          ...searchForOptions,
        ][0]?.id
      );
    }
  };

  const onFocusInHandler = (): void => {
    if (!highlightedItem) {
      const allItemsArray = getAllItems(searchValue);
      setHighlightedItem(allItemsArray[0]?.adjustedId ?? allItemsArray[0]?.id);
    }
  };

  const toggleOpenShortcut = isMac
    ? GLOBAL_SEARCH_HOTKEY_MAC
    : GLOBAL_SEARCH_HOTKEY_WINDOWS;

  return (
    <ac-card class="global-search-body" id={GLOBAL_SEARCH_MODAL_ID}>
      <ac-flex direction={FlexDirection.column} class="width-percentage-100">
        <ac-flex
          alignItems={AlignItems.center}
          class="global-search-body-header"
        >
          <ac-icon
            icon={IconName.search}
            class="ac-margin-inline-end-sm ac-margin-inline-start-md"
          />
          <ac-field
            translations={{
              placeholder: t('MENU.GLOBAL_SEARCH.SEARCH_PLACEHOLDER'),
            }}
            inputRef={handleInputRef}
            onFocusInCallback={onFocusInHandler}
            value={searchValue}
            onInputCallback={onInputHandler}
            hideMessageContainer
            pattern={FieldPattern.alternative}
            autoFocus
          />
          {searchValue && (
            <ac-icon
              class="pointer ac-spacing-inline-start-sm"
              icon={IconName.cancel}
              onClick={(): void => onInputHandler(undefined)}
              color={Color.foreground}
            />
          )}
          <div className="ac-margin-inline-end-md">
            <GlobalSearchModalShortcut text={toggleOpenShortcut} />
          </div>
        </ac-flex>
        <div className="global-search-body-header ac-spacing-horizontal-md ac-spacing-top-md">
          <ac-divider
            spacing={DividerSpacing.none}
            color={DividerColor.gray200}
          />
        </div>
        <GlobalSearchModalList
          highlightedItem={highlightedItem}
          favoriteItems={favoriteItems}
          popularItems={popularItems}
          searchValue={searchValue}
          filteredMenuItems={filteredMenuItems}
          favoritesItemsIds={favoritesItemsIds}
          searchForNumberOptions={searchForNumberOptions}
          searchForOptions={searchForOptions}
          recentItems={recentItems}
          onFavoriteClickHandler={(id: string): void => {
            handleSetIsDirty(true);
            toggleFavorite(id);
            setHighlightedItem(undefined);
          }}
          onClickHandler={onClickHandler}
        />
      </ac-flex>
    </ac-card>
  );
};
