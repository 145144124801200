import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { mapMenuElementsToGlobalSearchElement } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/mapMenuElementsToGlobalSearchElement';
import { getProfileCentersContexts } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/centralAvailabilityInquiry';
import { getConfigurationMenuInMainApplication } from 'frontend-container/components/Menu/configuration/configuration';
import { profileCenterMenu } from 'frontend-container/components/Menu/configuration/profileCenters';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/reservationsLookup';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  ContextOption,
  getCurrentContextOption,
} from 'frontend-container/components/Menu/utils/getCurrentContext';

interface Props {
  selectedCro: Context | undefined;
  selectedProfileCenter: Context | undefined;
  selectedProperty: Context | undefined;
  mainApplicationMenu: MenuElement[];
}

export interface AllowedItems {
  globalSearchItems: GlobalSearchMenuElementItem[];
}

export const useAllowedItems = ({
  selectedCro,
  selectedProfileCenter,
  selectedProperty,
  mainApplicationMenu,
}: Props): AllowedItems => {
  const { t } = useTranslation();
  const allAccessConfiguration = useMemo(() => getAllAccessConfiguration(), []);
  const profileCentersContexts = useMemo(() => getProfileCentersContexts(), []);

  const currentContext = getCurrentContextOption();

  const allowedCroItems = useMemo(
    () =>
      getAllowedMenuItems(
        [reservationsLookupMenu, centralAvailabilityInquiryMenu],
        allAccessConfiguration
      ),
    [allAccessConfiguration]
  );

  const allowedProfileCenterItems = useMemo(() => {
    if (!profileCentersContexts.length) {
      return [];
    }

    return getAllowedMenuItems([profileCenterMenu], allAccessConfiguration);
  }, [allAccessConfiguration, profileCentersContexts.length]);

  const allowedConfigurationItems = useMemo(
    () =>
      getAllowedMenuItems(
        [getConfigurationMenuInMainApplication()],
        allAccessConfiguration
      ),
    [allAccessConfiguration]
  );

  const mainApplicationSearchItems: GlobalSearchMenuElementItem[] =
    useMemo(() => {
      const badgeText =
        currentContext !== ContextOption.property
          ? selectedProperty?.code
          : undefined;

      return mapMenuElementsToGlobalSearchElement(
        mainApplicationMenu,
        t,
        badgeText
      );
    }, [mainApplicationMenu, currentContext, selectedProperty?.code, t]);

  const configurationSearchItems: GlobalSearchMenuElementItem[] =
    useMemo(() => {
      return mapMenuElementsToGlobalSearchElement(allowedConfigurationItems, t);
    }, [allowedConfigurationItems, t]);

  const profileCenterSearchItems: GlobalSearchMenuElementItem[] =
    useMemo(() => {
      const badgeText =
        currentContext !== ContextOption.profileCenter
          ? selectedProfileCenter?.code
          : undefined;

      return mapMenuElementsToGlobalSearchElement(
        allowedProfileCenterItems,
        t,
        badgeText
      );
    }, [
      allowedProfileCenterItems,
      currentContext,
      selectedProfileCenter?.code,
      t,
    ]);

  const croSearchItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    const badgeText =
      currentContext !== ContextOption.cro ? selectedCro?.code : undefined;

    return mapMenuElementsToGlobalSearchElement(allowedCroItems, t, badgeText);
  }, [allowedCroItems, currentContext, selectedCro?.code, t]);

  return {
    globalSearchItems: [
      ...mainApplicationSearchItems,
      ...configurationSearchItems,
      ...profileCenterSearchItems,
      ...croSearchItems,
    ],
  };
};
