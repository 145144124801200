import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  customerPermissionKeys,
  GeneralCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const PROFILE_CENTERS_MENU_ID = 'menu-profile-centers';

export const profileCenterMenu: MenuElement = {
  translation: 'MENU.PROFILE_CENTERS.TITLE',
  icon: IconName.profileDetails,
  id: PROFILE_CENTERS_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.ProfileCenters,
      source: AccessSource.Tenant,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.profiles}/global-view/dashboard`,
      id: `${PROFILE_CENTERS_MENU_ID}-dashboard`,
      code: 'ProfilesDashboard',
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/all`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/individual`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/company`,
        `${acConfig.newFrontendUrls.profiles}/global-view/dashboard/travel-agent`,
      ],
      translation: 'MENU.PROFILE_CENTERS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: customerPermissionKeys.profiles.individual.viewDetails,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.profiles.company.viewDetails,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.profiles.travelAgent.viewDetails,
            source: AccessSource.Tenant,
          },
          {
            key: customerPermissionKeys.profiles.shared.dataProtectionOfficer
              .seeProfilesDashboard,
            source: AccessSource.Tenant,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
    },
  ],
};

export const configurationProfileCentersMenu: MenuElement = {
  translation: 'MENU.PROFILE_CENTERS.CONFIGURATION_TITLE',
  icon: IconName.consent,
  id: PROFILE_CENTERS_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.ProfileCenters,
      source: AccessSource.Tenant,
    },
  ],
  permissionsConfiguration: {
    permissions: [
      {
        key: customerPermissionKeys.configuration.profileCenters.view,
        source: AccessSource.Tenant,
      },
    ],
    permissionsConjunction: PermissionsConjunction.And,
  },
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/profile-center-structure`,
      id: `${PROFILE_CENTERS_MENU_ID}-profile-center-structure`,
      translation: 'MENU.PROFILE_CENTERS.CONFIGURATION_TITLE',
    },
  ],
};
