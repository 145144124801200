import { useTranslation } from 'react-i18next';
import { TranslationFunction } from 'frontend-container/i18n/translation';

export const getRemoteSupportTranslationKey = (key: string): string =>
  `MAINTENANCE_PANEL.REMOTE_SUPPORT.${key}`;

export const useRemoteSupportTranslation = (): { t: TranslationFunction } => {
  const { t: originalTranslation } = useTranslation();

  return {
    t: (key: string): string =>
      originalTranslation(getRemoteSupportTranslationKey(key)),
  };
};
