import { useMemo } from 'react';
import { BusinessContextSection } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/BusinessContextSection';
import { businessContextSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/manifest';
import { useBusinessContextSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/presenter/context';
import { DesignSystemSection } from 'frontend-container/components/panels/maintenancePanel/features/designSystem/DesignSystemSection';
import { designSystemSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/designSystem/manifest';
import { useDesignSystemSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/designSystem/presenter/context';
import { EnvironmentDetailsSection } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/EnvironmentDetailsSection';
import { environmentDetailsSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/manifest';
import { useEnvironmentDetailsSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/presenter/context';
import { FeatureTogglesSection } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/FeatureTogglesSection';
import { featureTogglesSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/manifest';
import { useFeatureTogglesSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/presenter/context';
import { internetSpeedTestSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/manifest';
import { useInterneSpeedSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/presenter/context';
import { InternetSpeedTestSection } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/Section';
import { microservicesVersionsSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/manifest';
import { MicroservicesVersionsSection } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/MicroservicesVersionsSection';
import { useMicroservicesVersionsSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/presenter/context';
import { remoteSupportSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/manifest';
import { useRemoteSupportSectionPresenterContext } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/presenter/context';
import { RemoteSupportSection } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/Section';

import {
  PageSectionDefinition,
  PageSectionDefinitionBuilder,
  PageSectionsContainer,
  PageSectionsContainerTheme,
} from '@ac/react-infrastructure';

export const MaintenancePanelSections = (): JSX.Element => {
  const environmentDetailsPresenter =
    useEnvironmentDetailsSectionPresenterContext();
  const microservicesVersionsPresenter =
    useMicroservicesVersionsSectionPresenterContext();
  const businessContextPresenter = useBusinessContextSectionPresenterContext();
  const designSystemPresenter = useDesignSystemSectionPresenterContext();
  const featureTogglesPresenter = useFeatureTogglesSectionPresenterContext();
  const internetSpeedTestPresenter = useInterneSpeedSectionPresenterContext();
  const remoteSupportPresenter = useRemoteSupportSectionPresenterContext();

  const sections = useMemo<PageSectionDefinition[]>(
    () => [
      new PageSectionDefinitionBuilder()
        .withId(environmentDetailsSectionManifest.id)
        .withComponent(<EnvironmentDetailsSection />)
        .withPresenter(environmentDetailsPresenter)
        .build(),
      new PageSectionDefinitionBuilder()
        .withId(microservicesVersionsSectionManifest.id)
        .withComponent(<MicroservicesVersionsSection />)
        .withPresenter(microservicesVersionsPresenter)
        .build(),
      new PageSectionDefinitionBuilder()
        .withId(businessContextSectionManifest.id)
        .withComponent(<BusinessContextSection />)
        .withPresenter(businessContextPresenter)
        .build(),
      new PageSectionDefinitionBuilder()
        .withId(designSystemSectionManifest.id)
        .withComponent(<DesignSystemSection />)
        .withPresenter(designSystemPresenter)
        .build(),
      new PageSectionDefinitionBuilder()
        .withId(featureTogglesSectionManifest.id)
        .withComponent(<FeatureTogglesSection />)
        .withPresenter(featureTogglesPresenter)
        .build(),
      new PageSectionDefinitionBuilder()
        .withId(internetSpeedTestSectionManifest.id)
        .withComponent(<InternetSpeedTestSection />)
        .withPresenter(internetSpeedTestPresenter)
        .build(),
      new PageSectionDefinitionBuilder()
        .withId(remoteSupportSectionManifest.id)
        .withComponent(<RemoteSupportSection />)
        .withPresenter(remoteSupportPresenter)
        .build(),
    ],
    [
      environmentDetailsPresenter,
      microservicesVersionsPresenter,
      businessContextPresenter,
      designSystemPresenter,
      featureTogglesPresenter,
      internetSpeedTestPresenter,
      remoteSupportPresenter,
    ]
  );

  return (
    <PageSectionsContainer
      sections={sections}
      theme={PageSectionsContainerTheme.WithoutSideMenu}
    />
  );
};
