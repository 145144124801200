import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  propertyPermissionKeys,
  Setting,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const reservationPermissions = propertyPermissionKeys.reservations;
const inventoryPermissions = propertyPermissionKeys.inventory;
export const reservationsDashboardLink = `${acConfig.newFrontendUrls.reservations}/reservations`;
export const individualReservationsDashboardLink = `${acConfig.newFrontendUrls.individualReservations}/dashboard`;
export const reservationsAvailabilityLink = `${acConfig.newFrontendUrls.reservations}/availability/search`;
export const availabilityDetailedLink = `${acConfig.newFrontendUrls.reports}/availability/detailed`;
export const createReservationLink = `${acConfig.newFrontendUrls.profiles}/prestep/reservation`;

const RESERVATIONS_MENU_ID = 'menu-reservations';

export const reservationsMenu: MenuElement = {
  translation: 'MENU.RESERVATIONS.TITLE',
  icon: IconName.reservations,
  id: RESERVATIONS_MENU_ID,
  items: [
    {
      get link(): string {
        const propertyContextData = getPropertyContextData();
        const isEmberToReactReservationsDashboardReactEnabled =
          propertyContextData?.featureToggles.find((toggle) => {
            return (
              toggle.featureName ===
              FeatureToggleName.EmberToReactReservationsDashboardReactEnabled
            );
          })?.enabled;

        return isEmberToReactReservationsDashboardReactEnabled
          ? individualReservationsDashboardLink
          : reservationsDashboardLink;
      },
      aliases: [
        acConfig.newFrontendUrls.reservations,
        `${acConfig.newFrontendUrls.individualReservations}/dashboard`,
        `${acConfig.newFrontendUrls.individualReservations}/reservation/new`,
        `${acConfig.newFrontendUrls.individualReservations}/reservation`,
      ],
      id: `${RESERVATIONS_MENU_ID}-dashboard`,
      code: 'ReservationDashboard',
      translation: 'MENU.RESERVATIONS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.dashboard.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}V`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}V`,
      keyCode: 86,
    },
    {
      link: reservationsAvailabilityLink,
      id: `${RESERVATIONS_MENU_ID}-availability`,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/availability/search/room-types`,
        `${acConfig.newFrontendUrls.reservations}/availability/search/rate-plans`,
        acConfig.newFrontendUrls.availability,
      ],
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.manageReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      translation: 'MENU.RESERVATIONS.ITEMS.AVAILABILITY',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}Q`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}Q`,
      keyCode: 81,
    },
    {
      link: availabilityDetailedLink,
      code: 'RoomTypeAvailability',
      id: `${RESERVATIONS_MENU_ID}-room-type-availability`,
      translation: 'MENU.RESERVATIONS.ITEMS.ROOM_TYPE_AVAILABILITY',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.viewReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}X`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}X`,
      keyCode: 88,
    },
    {
      link: `${acConfig.newFrontendUrls.rateManager}/inventory-balance-dashboard`,
      id: `${RESERVATIONS_MENU_ID}-inventory-balance-dashboard`,
      featureToggles: [
        {
          key: FeatureToggleName.IntegrationIHG,
          source: AccessSource.Property,
        },
      ],
      translation: 'MENU.RATES.ITEMS.INVENTORY_BALANCE_DASHBOARD',
    },
    {
      link: createReservationLink,
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations/new`,
        `${acConfig.newFrontendUrls.itinerary}/itinerary/new`,
        `${acConfig.newFrontendUrls.itinerary}/itinerary`,
      ],
      translation: 'MENU.RESERVATIONS.ITEMS.NEW',
      id: `${RESERVATIONS_MENU_ID}-create`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.manageReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}1`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}1`,
      keyCode: 49,
    },
    {
      link: `${acConfig.newFrontendUrls.inventory}/items`,
      id: `${RESERVATIONS_MENU_ID}-inventory-items`,
      aliases: [acConfig.newFrontendUrls.inventory],
      code: 'ItemInventoryDashboard',
      translation: 'MENU.RESERVATIONS.ITEMS.INVENTORY_ITEMS',
      settings: [
        { key: Setting.InventoryItems, source: AccessSource.Property },
      ],
      permissionsConfiguration: {
        permissions: [
          {
            key: inventoryPermissions.viewItemInventoryDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
};
