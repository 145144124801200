import { useMemo, useState } from 'react';
import { GLOBAL_SEARCH_RECENT_STORAGE_KEY } from 'frontend-container/components/Menu/components/GlobalSearchModal/configConstants';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { LocalStorage } from 'frontend-container/shared/storage/localStorage';

interface Props {
  globalSearchItems: GlobalSearchMenuElementItem[];
}

export interface RecentItems {
  recentItems: GlobalSearchMenuElementItem[];
  setRecentIds: (selectedId: string) => void;
}

export const useRecentItems = ({ globalSearchItems }: Props): RecentItems => {
  const getLocalStorageRecentIds = (): string[] =>
    LocalStorage.getParsed<string>(GLOBAL_SEARCH_RECENT_STORAGE_KEY)?.split(
      ','
    ) ?? [];

  const [recentItemsIds, setRecentItemsIds] = useState<string[]>(
    getLocalStorageRecentIds
  );

  const recentItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    if (!recentItemsIds.length) {
      return [];
    }

    return recentItemsIds
      .map((recentId) => {
        return globalSearchItems.find(
          (allowedItem) => allowedItem.id === recentId
        );
      })
      .filter((el) => !!el)
      .map((el: GlobalSearchMenuElementItem) => ({
        ...el,
        adjustedId: `${el.id}-recent`,
      }));
  }, [globalSearchItems, recentItemsIds]);

  const setRecentIds = (selectedId: string): void => {
    const indexOfIdInRecentElements = recentItemsIds.indexOf(selectedId);
    if (indexOfIdInRecentElements === -1) {
      if (recentItemsIds.length >= 5) {
        const newRecentItems = recentItemsIds.slice(0, 4);
        setRecentItemsIds([selectedId, ...newRecentItems]);
        LocalStorage.set(
          GLOBAL_SEARCH_RECENT_STORAGE_KEY,
          [selectedId, ...newRecentItems].join(',')
        );
      } else {
        setRecentItemsIds([selectedId, ...recentItemsIds]);
        LocalStorage.set(
          GLOBAL_SEARCH_RECENT_STORAGE_KEY,
          [selectedId, ...recentItemsIds].join(',')
        );
      }
    } else {
      const itemsWithoutSelected = recentItemsIds.filter(
        (element) => element !== selectedId
      );

      setRecentItemsIds([selectedId, ...itemsWithoutSelected]);
      LocalStorage.set(
        GLOBAL_SEARCH_RECENT_STORAGE_KEY,
        [selectedId, ...itemsWithoutSelected].join(',')
      );
    }
  };

  return { recentItems, setRecentIds };
};
