import {
  ContextType,
  DateTimeHook,
} from 'frontend-container/components/Menu/components/Context';
import { useBusinessDateTime } from 'frontend-container/components/Menu/components/Context/components/ContextDropdown/BusinessDate';
import { DateTimeHookPayload } from 'frontend-container/components/Menu/components/Context/dateTimeHook';
import { useCroDateTime } from 'frontend-container/components/Menu/components/CroContext/useCroDateTime';
import { useProfileCentersDateTime } from 'frontend-container/components/Menu/components/ProfileCentersContext/useProfileCentersDateTime';

export const useDateTimeContext = (
  context: ContextType | undefined
): DateTimeHook => {
  const { date, dateFormat, time, timeFormat } = useProfileCentersDateTime();
  const {
    date: croDate,
    dateFormat: croDateFormat,
    time: croTime,
    timeFormat: croTimeFormat,
  } = useCroDateTime();
  const {
    date: propertyDate,
    dateFormat: propertyDateFormat,
    time: propertyTime,
    timeFormat: propertyTimeFormat,
  } = useBusinessDateTime();

  return (): DateTimeHookPayload => {
    switch (context) {
      case ContextType.CRO:
        return {
          date,
          time,
          dateFormat,
          timeFormat,
        };
      case ContextType.PROFILE_CENTER:
        return {
          date: croDate,
          time: croTime,
          dateFormat: croDateFormat,
          timeFormat: croTimeFormat,
        };
      default:
        return {
          date: propertyDate,
          time: propertyTime,
          dateFormat: propertyDateFormat,
          timeFormat: propertyTimeFormat,
        };
    }
  };
};
