import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  getCustomerContextData,
  getPropertyContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';

import {
  FeatureToggleName,
  GolfCustomerSettingsKeys,
  golfPropertyPermissionKeys,
  GolfPropertySettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export const GOLF_MENU_ID = 'menu-golf';

export const getGolfMenu = (): MenuElement => {
  const propertyContextData = getPropertyContextData();
  const customerContextData = getCustomerContextData();

  const propertySettings = propertyContextData
    ? propertyContextData?.settings.effectiveSettingsDetails
    : [];
  const customerSettings = customerContextData
    ? customerContextData?.settings.effectiveSettings
    : [];

  const settings = [...propertySettings, ...customerSettings];

  const hasPrestep = (
    settings.find(
      ({ code }) => code === GolfPropertySettingsKeys.GolfUsePrestep
    )?.value as { value: boolean }
  )?.value;

  return {
    translation: 'MENU.GOLF.TITLE',
    icon: IconName.golf,
    id: GOLF_MENU_ID,
    permissionsConfiguration: {
      permissions: [
        {
          key: golfPropertyPermissionKeys.viewGolfModule,
          source: AccessSource.Property,
        },
      ],
      permissionsConjunction: PermissionsConjunction.And,
    },
    settings: [
      {
        key: GolfCustomerSettingsKeys.GolfReservations,
        source: AccessSource.Property,
      },
    ],
    items: [
      {
        link: `${acConfig.newFrontendUrls.golf}/reservations/dashboard`,
        translation: 'MENU.GOLF.ITEMS.DASHBOARD',
        id: `${GOLF_MENU_ID}-dashboard`,
        featureToggles: [
          {
            key: FeatureToggleName.GolfDashboard,
            source: AccessSource.Property,
          },
        ],
        aliases: [`${acConfig.newFrontendUrls.golf}/reservations/`],
      },
      {
        link: `${acConfig.newFrontendUrls.golf}/reservations/planner`,
        translation: 'MENU.GOLF.ITEMS.PLANNER',
        id: `${GOLF_MENU_ID}-planner`,
        featureToggles: [
          { key: FeatureToggleName.GolfPlanner, source: AccessSource.Property },
        ],
      },
      {
        link: hasPrestep
          ? `${acConfig.newFrontendUrls.profiles}/prestep/golf`
          : `${acConfig.newFrontendUrls.golf}/reservations/new`,
        translation: 'MENU.GOLF.ITEMS.CREATE_RESERVATION',
        id: `${GOLF_MENU_ID}-create-reservation`,
        featureToggles: [
          {
            key: FeatureToggleName.GolfReservationsModule,
            source: AccessSource.Property,
          },
          {
            key: FeatureToggleName.GolfCreateReservation,
            source: AccessSource.Property,
          },
        ],
        aliases: [
          `${acConfig.newFrontendUrls.golf}/reservation/new`,
          `${acConfig.newFrontendUrls.golf}/reservations/new`,
        ],
        permissionsConfiguration: {
          permissions: [
            {
              key: golfPropertyPermissionKeys.createGolfReservation,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
      {
        link: `${acConfig.newFrontendUrls.golf}/configuration/course/dashboard`,
        translation: 'MENU.GOLF.ITEMS.COURSE_MANAGER',
        id: `${GOLF_MENU_ID}-course-manager`,
        aliases: [`${acConfig.newFrontendUrls.golf}/configuration/`],
        permissionsConfiguration: {
          permissions: [
            {
              key: golfPropertyPermissionKeys.viewGolfCourseManager,
              source: AccessSource.Property,
            },
          ],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
    ],
    featureToggles: [
      {
        key: FeatureToggleName.GolfReservationsModule,
        source: AccessSource.Property,
      },
    ],
  };
};
