import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';

import { KeyboardKey } from '@ac/web-components';

export const getHighlightedIdOrClickedElement = (
  highlightedItem: string | undefined,
  allItemsArray: GlobalSearchMenuElementItem[],
  event: KeyboardEvent
): GlobalSearchMenuElementItem | string | undefined => {
  if (!highlightedItem) {
    return;
  }
  const highlightedItemIndex = allItemsArray.findIndex(
    (element) => element.adjustedId === highlightedItem
  );

  if (highlightedItemIndex === -1) {
    return;
  }

  const allItemsArrayLength = allItemsArray.length;

  if (event.key === KeyboardKey.ArrowUp) {
    event.preventDefault();
    const newIndex =
      highlightedItemIndex === 0
        ? allItemsArrayLength - 1
        : highlightedItemIndex - 1;

    return allItemsArray[newIndex].adjustedId ?? allItemsArray[newIndex].id;
  }

  if (event.key === KeyboardKey.ArrowDown) {
    event.preventDefault();
    const newIndex =
      highlightedItemIndex === allItemsArrayLength - 1
        ? 0
        : highlightedItemIndex + 1;

    return allItemsArray[newIndex].adjustedId ?? allItemsArray[newIndex].id;
  }

  if (event.key === KeyboardKey.Enter) {
    event.preventDefault();

    const selectedItem: GlobalSearchMenuElementItem | undefined =
      allItemsArray.find((el) => el.adjustedId === highlightedItem);

    if (selectedItem) {
      return selectedItem;
    }

    return;
  }

  return;
};
