import { AlignItems, TextColor } from '@ac/web-components';

interface Props {
  text: string;
}

export const GlobalSearchModalShortcut = ({
  text,
}: Props): JSX.Element | null => {
  return (
    <ac-flex
      class="global-search-modal-shortcut ac-margin-inline-start-sm"
      alignItems={AlignItems.center}
    >
      <ac-text color={TextColor.foreground}>{text}</ac-text>
    </ac-flex>
  );
};
