import { createContext } from 'react';
import { FavoritesListStore } from 'frontend-container/components/Menu/store/favorites/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const FavoritesListContext = createContext<
  FavoritesListStore | undefined
>(undefined);

export const useFavoritesContext = (): FavoritesListStore =>
  useDefinedContext(FavoritesListContext);
