import { useCallback, useState } from 'react';
import { userPreferencesService } from 'frontend-container/components/Menu/components/User/UserPreferences/service';

export interface FavoritesListStore {
  favoritesItemsIds: string[];
  toggleFavorite: (id: string) => void;
}

export const useFavoriteListStore = (): FavoritesListStore => {
  const [favoritesItemsIds, setFavoritesItemsIds] = useState<string[]>(() =>
    userPreferencesService.getMenuV2Favorites()
  );

  const toggleFavorite = useCallback((id: string): void => {
    const favoriteMenuItems = favoritesItemsIds;
    const favoriteIndex = favoriteMenuItems.indexOf(id);

    if (favoriteIndex > -1) {
      favoriteMenuItems.splice(favoriteIndex, 1);
    } else {
      favoriteMenuItems.push(id);
    }

    setFavoritesItemsIds([...favoriteMenuItems]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    favoritesItemsIds,
    toggleFavorite,
  };
};
