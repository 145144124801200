import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  resourceManagementPropertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const RESOURCE_MANAGEMENT_MENU_ID = 'menu-resource-management';

export const resourceManagementMenu: MenuElement = {
  translation: 'MENU.RESOURCE_MANAGEMENT.TITLE',
  icon: IconName.concierge,
  id: RESOURCE_MANAGEMENT_MENU_ID,
  items: [
    {
      link: `${acConfig.newFrontendUrls.resourceManagement}/resources`,
      translation: 'MENU.RESOURCE_MANAGEMENT.ITEMS.DASHBOARD',
      id: `${RESOURCE_MANAGEMENT_MENU_ID}-dashboard`,
      permissionsConfiguration: {
        permissions: [
          {
            key: resourceManagementPropertyPermissionKeys.viewStaff,
            source: AccessSource.Property,
          },
          {
            key: resourceManagementPropertyPermissionKeys.viewMyDepartmentStaff,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
    {
      link: `${acConfig.newFrontendUrls.resourceManagement}/leave-calendar`,
      id: `${RESOURCE_MANAGEMENT_MENU_ID}-leave-calendar`,
      translation: 'MENU.RESOURCE_MANAGEMENT.ITEMS.LEAVE_CALENDAR',
      permissionsConfiguration: {
        permissions: [
          {
            key: resourceManagementPropertyPermissionKeys.viewLeave,
            source: AccessSource.Property,
          },
          {
            key: resourceManagementPropertyPermissionKeys.viewMyDepartmentLeave,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
    },
  ],
  featureToggles: [
    {
      key: FeatureToggleName.ResourceManagementModule,
      source: AccessSource.Property,
    },
  ],
};
