import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const ORGANIZATION_STRUCTURE_MENU_ID = 'menu-organization-structure';

export const organizationStructureMenu: MenuElement = {
  translation: 'MENU.ORGANIZATION_STRUCTURE.TITLE',
  icon: IconName.structure,
  id: ORGANIZATION_STRUCTURE_MENU_ID,
  items: [
    {
      link: `${acConfig.newFrontendUrls.configurationv2}`,
      id: `${ORGANIZATION_STRUCTURE_MENU_ID}-dashboard`,
      aliases: [
        `${acConfig.newFrontendUrls.configurationv2}/customers/:customerId/organization-structure`,
      ],
      translation: 'MENU.ORGANIZATION_STRUCTURE.ITEMS.DASHBOARD',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}'`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}'`,
    },
  ],
};
