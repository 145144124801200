import { userService } from 'frontend-container/components/Menu/components/User/service';
import { userPreferencesService } from 'frontend-container/components/Menu/components/User/UserPreferences/service';
import { ReloadCurrentBusinessContextDataParameters } from 'frontend-container/publicApi/windowObject';
import { notifyAboutBusinessContextChange } from 'frontend-container/shared/businessContext/events/notifyAboutChange';
import {
  getBusinessContextContainer,
  getBusinessContextData,
} from 'frontend-container/shared/businessContext/getBusinessContext';
import { getCurrentUnitFromBusinessContext } from 'frontend-container/shared/businessContext/getCurrentUnit';
import { definedProps } from 'frontend-container/utils/definedProps';
import { queuedFunction } from 'frontend-container/utils/queuedFunction';

import {
  AllBusinessContextElements,
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextLoader,
} from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';

export const defaultElementsToReload: AllBusinessContextElements = {
  customer: {
    customContext: true,
    permissions: true,
    settings: true,
  },
  system: {
    permissions: true,
  },
  user: {
    units: true,
    details: false,
    preferences: false,
  },
  property: {
    permissions: true,
    settings: true,
    details: false,
    businessDate: false,
  },
  centralReservationOffice: {
    permissions: true,
    office: false,
  },
  profileCenters: {
    details: false,
  },
};

const defaultParameters: ReloadCurrentBusinessContextDataParameters = {
  elementsToReload: defaultElementsToReload,
};

export const reloadCurrentBusinessContextData = queuedFunction(
  async ({
    elementsToReload = defaultElementsToReload,
  }: ReloadCurrentBusinessContextDataParameters = defaultParameters): Promise<void> => {
    const container = getBusinessContextContainer();
    const context = getBusinessContextData();

    if (!context || !container) {
      throw new Error(
        'Cannot reload business context without context defined in the container'
      );
    }

    const contextLoader = new SepModuleBusinessContextLoader();
    contextLoader.clearApiCache(elementsToReload);
    container.clearCache();

    const currentUnit = getCurrentUnitFromBusinessContext();
    const tenantId = currentUnit.tenantId;
    if (tenantId) {
      const updatedPartialContext = await contextLoader.load({
        tenantId,
      });

      // assign partial context to validate preferences & contexts
      container.context = updatedPartialContext;

      userPreferencesService.initializeDefaultContexts(
        userService.getCurrentUserPreferences()
      );
      await userPreferencesService.validateUserPreferences();

      const newUnit: BusinessContextUnitIdentifier = {
        centralReservationOfficeId: currentUnit.centralReservationOfficeId
          ? SessionService.getCentralReservationOfficeId()
          : undefined,
        propertyId: currentUnit.propertyId
          ? SessionService.getPropertyId()
          : undefined,
        profileCenterId: currentUnit.profileCenterId
          ? SessionService.getProfileCenterId()
          : undefined,
      };

      const hasAnotherDataToLoad = Object.keys(definedProps(newUnit)).length;
      if (hasAnotherDataToLoad) {
        const updatedContext = await contextLoader.loadForDifferentScope(
          updatedPartialContext,
          {
            ...newUnit,
            tenantId,
          }
        );

        container.context = updatedContext;
      }
    } else {
      container.context = await contextLoader.load(currentUnit);
    }

    notifyAboutBusinessContextChange();
  }
);
