import { FC, useMemo } from 'react';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { getConfigurationEntityDescription } from 'frontend-container/utils/getConfigurationEntityDescription';

import { GenericOverrideEntity } from '@ac/library-api';
import {
  AlignItems,
  FlexDirection,
  IconName,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

interface Props {
  firstName?: string;
  lastName?: string;
  email?: string;
  department?: GenericOverrideEntity;
  jobTitle?: GenericOverrideEntity;
}

export const UserInfotipHeader: FC<Props> = ({
  firstName,
  lastName,
  email,
  department,
  jobTitle,
}) => {
  const formattedName = `${lastName ?? ''}, ${firstName ?? ''}`;

  const jobDepartment = useMemo(() => {
    return `${
      jobTitle
        ? getConfigurationEntityDescription(jobTitle?.descriptionMap) + ', '
        : ''
    }${
      department
        ? getConfigurationEntityDescription(department?.descriptionMap)
        : ''
    }`;
  }, [department, jobTitle]);

  const isMenuV2Enabled = getIsMenuV2Enabled();

  return isMenuV2Enabled ? (
    <ac-flex direction={FlexDirection.column} class="ac-gap-xs">
      {jobDepartment && (
        <ac-text size={TextSize.sm} color={TextColor.darkGray}>
          {jobDepartment}
        </ac-text>
      )}
      <ac-text weight={TextWeight.semibold}>{formattedName}</ac-text>
      {email && (
        <ac-text size={TextSize.sm} color={TextColor.darkGray}>
          {email}
        </ac-text>
      )}
    </ac-flex>
  ) : (
    <>
      <ac-flex
        alignItems={AlignItems.center}
        class="ac-spacing-bottom-sm ac-gap-sm"
      >
        <ac-icon icon={IconName.profile} />
        <ac-flex direction={FlexDirection.column}>
          <ac-text weight={TextWeight.semibold}>{formattedName}</ac-text>
          <ac-text size={TextSize.sm}>{jobDepartment}</ac-text>
        </ac-flex>
      </ac-flex>
      <ac-flex class="ac-spacing-bottom-sm">
        <ac-box class="ac-spacing-left-lg">
          <ac-text class="ac-spacing-left-s" color={TextColor.lightGray}>
            {email}
          </ac-text>
        </ac-box>
      </ac-flex>
    </>
  );
};
