import { Config, ConfigBuilder } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

export const AcPropertyIdHeaderName = 'AC-Property-ID';

type Headers = Record<string, string | number | boolean | null | undefined>;

export const getMainHeaders = (
  propertyId: string | undefined,
  skipTenantHeader: boolean = false
): Headers | undefined => {
  let headers: Headers = {};

  if (!LoginService.isLoggedIn()) {
    return;
  }

  const token = LoginService.authData() && LoginService.authData()?.token;
  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  const tenantId = LoginService.authData()?.tenantId;
  if (tenantId && !skipTenantHeader) {
    headers = {
      ...headers,
      'AC-Tenant-ID': tenantId,
    };
  }

  if (propertyId && !LoginService.isSuperUser()) {
    headers = {
      ...headers,
      [AcPropertyIdHeaderName]: propertyId,
    };
  }

  return headers;
};

export const getStaticConfiguration = (): Config<undefined> => {
  return new ConfigBuilder()
    .setHeaders(getMainHeaders(SessionService.getPropertyId()))
    .getConfig();
};
