import { BusinessContextContainer } from 'frontend-container/publicApi';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import { getCurrentUnitFromBusinessContext } from 'frontend-container/shared/businessContext/getCurrentUnit';
import { businessContextProvider } from 'frontend-container/shared/businessContext/provider/providerInstance';
import { getCurrentGlobalEventBus } from 'frontend-container/shared/communication/getGlobalEventBus';
import { definedProps } from 'frontend-container/utils/definedProps';
import { setUpWebSocketsApi } from 'frontend-container/utils/setUpWebSocketsApi';

import {
  BusinessContextUnitIdentifier,
  globalBusinessContextUpdatedEventCreator,
  SepModuleBusinessContextData,
} from '@ac/library-api';
import { SessionService } from '@ac/library-utils/dist/services';

export const selectUnitInBusinessContext = async (
  unit: BusinessContextUnitIdentifier
): Promise<SepModuleBusinessContextData> => {
  const contextContainer = window.ACP?.businessContext;
  if (!contextContainer) {
    throw new Error(
      'The context container is not defined. Please use setInitialBusinessContextForUnit() firstly.'
    );
  }

  const isNewMenu =
    contextContainer.context.user.preferences?.preferences?.displayNewMenu;
  const targetUnit = isNewMenu
    ? { ...getCurrentUnitFromBusinessContext(), ...definedProps(unit) }
    : unit;

  const currentBusinessContextData = getBusinessContextData();
  const newBusinessContextData =
    await businessContextProvider.getBusinessContext(targetUnit);

  if (currentBusinessContextData !== newBusinessContextData) {
    saveNewBusinessContext(
      targetUnit,
      contextContainer,
      newBusinessContextData
    );

    setUpWebSocketsApi();
  }

  return newBusinessContextData;
};

const saveNewBusinessContext = async (
  unit: BusinessContextUnitIdentifier | undefined,
  contextContainer: BusinessContextContainer,
  context: SepModuleBusinessContextData
): Promise<void> => {
  if (unit?.propertyId) {
    SessionService.setPropertyId(unit.propertyId);
  }
  if (unit?.centralReservationOfficeId) {
    SessionService.setCentralReservationOfficeId(
      unit.centralReservationOfficeId
    );
  }
  if (unit?.profileCenterId) {
    SessionService.setProfileCenterId(unit.profileCenterId);
  }

  contextContainer.context = context;

  getCurrentGlobalEventBus().dispatch(
    globalBusinessContextUpdatedEventCreator(context)
  );
};
