import { createContext } from 'react';

import {
  PageSectionPresenterStore,
  useDefinedContext,
} from '@ac/react-infrastructure';

export const RemoteSupportSectionPresenterContext = createContext<
  PageSectionPresenterStore | undefined
>(undefined);

export const useRemoteSupportSectionPresenterContext =
  (): PageSectionPresenterStore =>
    useDefinedContext(RemoteSupportSectionPresenterContext);
