import { cloneElement, useState } from 'react';

import {
  FloatingNode,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useHover,
  useInteractions,
} from '@floating-ui/react';

interface Props {
  floatingContent: JSX.Element | undefined;
  nested?: boolean;
  placement?: Placement;
  children: JSX.Element;
  isDelayRestMode?: boolean;
}

export const SafeFloatingWrapper = ({
  floatingContent,
  nested,
  placement,
  children,
  isDelayRestMode,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const nodeId = useFloatingNodeId();

  const delayRestMode = isDelayRestMode
    ? {
        restMs: 100,
        delay: {
          open: 400,
          close: 0,
        },
      }
    : {};

  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: placement ?? 'bottom-start',
    middleware: [nested ? offset(-4) : undefined, shift()],
    nodeId,
  });

  const hover = useHover(context, {
    ...delayRestMode,
    handleClose: safePolygon({
      requireIntent: false,
      buffer: 1,
      blockPointerEvents: true,
    }),
  });

  const dismiss = useDismiss(context, {
    ancestorScroll: nested,
    capture: true,
  });

  const { getFloatingProps, getReferenceProps } = useInteractions([
    hover,
    dismiss,
  ]);

  return (
    <>
      {cloneElement(children, {
        ref: refs.setReference,
        ...getReferenceProps(),
      })}
      <FloatingNode id={nodeId}>
        {isOpen && (
          <FloatingPortal>
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={{
                ...floatingStyles,
                zIndex: 'var(--z-index-select-main-menu)',
              }}
            >
              {floatingContent}
            </div>
          </FloatingPortal>
        )}
      </FloatingNode>
    </>
  );
};
