const promiseQueue = <T>() => {
  let pendingPromise: Promise<T> | undefined;

  return {
    add: async (callback: () => Promise<T>): Promise<T> => {
      if (pendingPromise) {
        await pendingPromise;
      }

      pendingPromise = callback();
      const result = await pendingPromise;
      pendingPromise = undefined;

      return result;
    },
  };
};

export const queuedFunction = <TArgs, TReturnValue>(
  fn: (args: TArgs) => Promise<TReturnValue>
) => {
  const queue = promiseQueue<TReturnValue>();

  return (args: TArgs) => {
    const callback = () => fn(args);

    return queue.add(callback);
  };
};
