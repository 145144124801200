export const isItemHighlighted = (
  text: string,
  searchPhrase: string
): boolean => {
  return (
    text.toLowerCase().startsWith(searchPhrase.toLowerCase()) ||
    text
      .toLowerCase()
      .split(' ')
      .some((word) => word.startsWith(searchPhrase.toLowerCase()))
  );
};
