import { useMemo } from 'react';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';

interface Props {
  globalSearchItems: GlobalSearchMenuElementItem[];
  favoritesItemsIds: string[];
  popularLinks: string[];
}

export interface PopularItems {
  popularItems: GlobalSearchMenuElementItem[];
}

export const usePopularItems = ({
  globalSearchItems,
  favoritesItemsIds,
  popularLinks,
}: Props): PopularItems => {
  const popularItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    const allPopularItems: GlobalSearchMenuElementItem[] = popularLinks
      .map((popularLink) => {
        return globalSearchItems.find(
          (item: GlobalSearchMenuElementItem) => item.link === popularLink
        );
      })
      .filter((el) => !!el)
      .map((el: GlobalSearchMenuElementItem) => ({
        ...el,
        adjustedId: `${el.id}-popular`,
      }));

    return allPopularItems.filter(
      (popularItem) => !favoritesItemsIds.includes(popularItem.id)
    );
  }, [globalSearchItems, favoritesItemsIds, popularLinks]);

  return { popularItems };
};
