import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { BusinessContextUnitIdentifier } from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

export const getCurrentUnitFromBusinessContext =
  (): BusinessContextUnitIdentifier => {
    const contextData = getBusinessContextData();

    const currentCroId = contextData.centralReservationOffice?.office.id;
    const currentPropertyId = contextData.property?.details.id;
    const currentTenantId = contextData.customer?.details.id;
    const currentProfileCenterId = contextData.profileCenters?.details?.id;

    return {
      centralReservationOfficeId: currentCroId,
      profileCenterId: currentProfileCenterId,
      propertyId: currentPropertyId,
      tenantId: currentTenantId,
    };
  };

export const getCurrentUnitFromSessionService =
  (): BusinessContextUnitIdentifier => {
    const currentCroId = SessionService.getCentralReservationOfficeId();
    const currentPropertyId = SessionService.getPropertyId();
    const currentTenantId = LoginService.authData()?.tenantId;
    const currentProfileCenterId = SessionService.getProfileCenterId();

    return {
      centralReservationOfficeId: currentCroId,
      profileCenterId: currentProfileCenterId,
      propertyId: currentPropertyId,
      tenantId: currentTenantId,
    };
  };
