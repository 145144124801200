import { notifyAboutBusinessContextChange } from 'frontend-container/shared/businessContext/events/notifyAboutChange';

import { BaseApi, UserPreferences } from '@ac/library-api';
import { UserPreferencesApi } from '@ac/library-api/dist/api/v0/configuration/users';

export const updateUserPreferences = async (
  shouldUpdateModules?: boolean
): Promise<void> => {
  const container = window.ACP?.businessContext;
  const context = container?.context;

  if (!context || !container) {
    throw new Error(
      'Cannot mutate business context without context defined in the container'
    );
  }

  BaseApi.clearCache(new RegExp(UserPreferencesApi.ApiCacheKeyUserPreferences));

  const userPreferences =
    (await UserPreferencesApi.getMyPreferences()) as UserPreferences;

  context.user.preferences = userPreferences;
  container.context = context;

  if (shouldUpdateModules ?? true) {
    notifyAboutBusinessContextChange();
  }
};
