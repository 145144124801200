import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  ProfilesCustomerSettingsKeys,
  propertyPermissionKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const COMMISIONS_MENU_ID = 'menu-commissions';

export const commissionsMenu: MenuElement = {
  translation: 'MENU.COMMISSIONS.TITLE',
  icon: IconName.commission,
  id: COMMISIONS_MENU_ID,
  settings: [
    {
      key: ProfilesCustomerSettingsKeys.TravelAgentCommissions,
      source: AccessSource.Property,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.travelAgentCommissions}/dashboard`,
      aliases: [acConfig.newFrontendUrls.travelAgentCommissions],
      id: `${COMMISIONS_MENU_ID}-dashboard`,
      code: 'CommissionDashboard',
      translation: 'MENU.COMMISSIONS.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.travelAgentCommissions.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.Or,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}Z`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}Z`,
      keyCode: 90,
    },
  ],
};
