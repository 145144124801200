import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { MenuElementItem } from 'frontend-container/components/Menu/types';

import { Target } from '@ac/web-components';

export const handleMenuItemClick = (
  menuLink: string,
  items: MenuElementItem[],
  skipInMenuCheck?: boolean,
  openInNewTab?: boolean,
  reloadPage?: boolean
): void => {
  if (openInNewTab) {
    window.open(menuLink, Target.blank);

    return;
  }

  const redirectToLink = (link: string): Promise<void> =>
    handleMenuItemRedirect(link, items, undefined, skipInMenuCheck);

  const handleClickMenuSubItem = async (
    link: string,
    discardUnsavedChanges: boolean = false
  ): Promise<void> => {
    return handleUnsavedChangesRedirect(
      discardUnsavedChanges,
      link,
      redirectToLink
    );
  };

  handleClickMenuSubItem(menuLink);
  if (reloadPage) {
    window.location.reload();
  }
};
