import { businessContextSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/businessContext/manifest';
import { designSystemSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/designSystem/manifest';
import { environmentDetailsSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/environmentDetails/manifest';
import { featureTogglesSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/featureToggles/manifest';
import { internetSpeedTestSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/internetSpeedTest/manifest';
import { microservicesVersionsSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/microservicesVersions/manifest';
import { remoteSupportSectionManifest } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/manifest';
import { MaintenancePanelMode } from 'frontend-container/components/panels/maintenancePanel/store/state';

import {
  PageSectionSchemaItem,
  PageSectionSchemaItemBuilder,
} from '@ac/react-infrastructure';

interface MaintenancePanelSectionsSchema {
  environmentDetails: PageSectionSchemaItem;
  microservicesVersions: PageSectionSchemaItem;
  businessContext: PageSectionSchemaItem;
  designSystem: PageSectionSchemaItem;
  featureToggles: PageSectionSchemaItem;
  internetSpeedTest: PageSectionSchemaItem;
  remoteSupport: PageSectionSchemaItem;
}

export const getMaintenancePanelSectionsSchema = (
  mode: MaintenancePanelMode
): MaintenancePanelSectionsSchema => {
  const isFullMode = mode !== 'basic';

  return {
    environmentDetails: new PageSectionSchemaItemBuilder()
      .withManifest(environmentDetailsSectionManifest)
      .withInitialAppearance({ isVisible: true, isCollapsed: false })
      .build(),
    microservicesVersions: new PageSectionSchemaItemBuilder()
      .withManifest(microservicesVersionsSectionManifest)
      .withInitialAppearance({ isVisible: true, isCollapsed: true })
      .build(),
    businessContext: new PageSectionSchemaItemBuilder()
      .withManifest(businessContextSectionManifest)
      .withInitialAppearance({ isVisible: isFullMode, isCollapsed: true })
      .build(),
    designSystem: new PageSectionSchemaItemBuilder()
      .withManifest(designSystemSectionManifest)
      .withInitialAppearance({ isVisible: isFullMode, isCollapsed: true })
      .build(),
    featureToggles: new PageSectionSchemaItemBuilder()
      .withManifest(featureTogglesSectionManifest)
      .withInitialAppearance({ isVisible: isFullMode, isCollapsed: true })
      .build(),
    internetSpeedTest: new PageSectionSchemaItemBuilder()
      .withManifest(internetSpeedTestSectionManifest)
      .withInitialAppearance({ isVisible: true, isCollapsed: true })
      .build(),
    remoteSupport: new PageSectionSchemaItemBuilder()
      .withManifest(remoteSupportSectionManifest)
      .withInitialAppearance({ isVisible: true, isCollapsed: true })
      .build(),
  };
};
