import { MenuElementItem } from 'frontend-container/components/Menu/types';

import { FIQLOperators } from '@ac/library-api';
import { IconName } from '@ac/web-components';

export interface GlobalSearchMenuElementItem extends MenuElementItem {
  icon: IconName;
  id: string;
  // there is possibility to have duplicated items eg. popular and recent
  adjustedId?: string;
  parentName?: string;
  badgeLabel?: string;
  isMenuItem?: boolean;
  queryParams?: GlobalSearchMenuElementQueryParams;
}

export interface GlobalSearchMenuElementQueryParams {
  queryKey?: string;
  filterKey?: string;
  operator?: FIQLOperators;
  underscore?: boolean;
  isReservationCustomParam?: boolean;
}

export enum ItemType {
  recent = 'recent',
  item = 'item',
  search = 'search',
  searchNumber = 'searchNumber',
  favorite = 'favorite',
  popular = 'popular',
}
