import { FC, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addError,
  clearErrors,
} from 'frontend-container/components/Menu/components/ChangePasswordModal/domain/actions';
import { FieldName } from 'frontend-container/components/Menu/components/ChangePasswordModal/domain/interfaces';
import reducer, {
  initialState,
} from 'frontend-container/components/Menu/components/ChangePasswordModal/domain/reducer';
import { PasswordField } from 'frontend-container/components/Menu/components/PasswordField/PasswordField';
import { userService } from 'frontend-container/components/Menu/components/User/service';

import { ErrorDetail } from '@ac/library-api';
import {
  Color,
  ErrorObject,
  ErrorType,
  IconName,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import { ChangePasswordModalTestSelectors } from './testSelectors';

import './ChangePasswordModal.scss';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
}

export const ChangePasswordModal: FC<Props> = (props) => {
  const { onClose, onConfirm } = props;
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [state, dispatch] = useReducer(reducer, initialState);
  const [apiErrors, setApiErrors] = useState<ErrorDetail[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const passwordRequirements: Array<{
    title: string;
    rule: (value: string) => boolean;
  }> = [
    {
      title: t('MENU.MODAL.CHANGE_PASSWORD.PASSWORD_REQUIREMENTS.UPPERCASE'),
      rule: (value: string): boolean => /[A-Z]/.test(value),
    },
    {
      title: t('MENU.MODAL.CHANGE_PASSWORD.PASSWORD_REQUIREMENTS.LOWERCASE'),
      rule: (value: string): boolean => /[a-z]/.test(value),
    },
    {
      title: t('MENU.MODAL.CHANGE_PASSWORD.PASSWORD_REQUIREMENTS.DIGIT'),
      rule: (value: string): boolean => /\d/.test(value),
    },
    {
      title: t('MENU.MODAL.CHANGE_PASSWORD.PASSWORD_REQUIREMENTS.SPECIAL_CHAR'),
      rule: (value: string): boolean =>
        // eslint-disable-next-line no-useless-escape
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value),
    },
    {
      title: t('MENU.MODAL.CHANGE_PASSWORD.PASSWORD_REQUIREMENTS.LENGTH'),
      rule: (value: string): boolean => value.length >= 8,
    },
  ];

  const addErrorForField = (fieldName: FieldName, error: ErrorObject): void => {
    dispatch(addError({ field: fieldName, error }));
  };

  const getError = (description: string): ErrorObject => ({
    description,
    severity: ErrorType.error,
  });

  const getPasswordRequiredError = (): ErrorObject => {
    return getError(t('MENU.MODAL.CHANGE_PASSWORD.ERROR.REQUIRED'));
  };

  const validateNewPasswordField = (): boolean => {
    if (!newPassword) {
      addErrorForField('newPassword', getPasswordRequiredError());

      return false;
    }
    const areRequirementsFulfilled = !passwordRequirements.some(
      (req) => !req.rule(newPassword)
    );
    if (newPassword === oldPassword) {
      addErrorForField(
        'newPassword',
        getError(t('MENU.MODAL.CHANGE_PASSWORD.ERROR.SAME_PASSWORD'))
      );

      return false;
    }
    if (!areRequirementsFulfilled) {
      addErrorForField(
        'newPassword',
        getError(t('MENU.MODAL.CHANGE_PASSWORD.ERROR.NOT_FULFILLED'))
      );

      return false;
    }

    return true;
  };

  const validateNewPasswordConfirmationField = (): boolean => {
    if (!newPasswordConfirmation) {
      addErrorForField('newPasswordConfirmation', getPasswordRequiredError());

      return false;
    }
    if (newPassword !== newPasswordConfirmation) {
      addErrorForField(
        'newPasswordConfirmation',
        getError(t('MENU.MODAL.CHANGE_PASSWORD.ERROR.NOT_MATCHING'))
      );

      return false;
    }

    return true;
  };

  const validateOldPasswordField = (): boolean => {
    if (!oldPassword) {
      addErrorForField('oldPassword', getPasswordRequiredError());

      return false;
    }

    return true;
  };

  const clearValidationState = (): void => {
    dispatch(clearErrors());
  };

  const validateAllFields = (): boolean => {
    clearValidationState();

    return (
      validateOldPasswordField() &&
      validateNewPasswordConfirmationField() &&
      validateNewPasswordField()
    );
  };

  const handleSubmit = async (): Promise<void> => {
    const isFormValid = validateAllFields();
    if (!isFormValid) {
      return;
    }
    try {
      setIsLoading(true);

      await userService.changeMyPassword(oldPassword, newPassword);
      await userService.logout();

      onConfirm();
    } catch (error) {
      setApiErrors([...apiErrors, error]);
      setIsLoading(false);
    }
  };

  const isConfirmButtonDisabled = [
    newPassword,
    oldPassword,
    newPasswordConfirmation,
  ].some((item) => !item);
  const confirmButtonText = apiErrors.length
    ? t('MENU.MODAL.COMMON.TRY_AGAIN')
    : t('MENU.MODAL.CHANGE_PASSWORD.TITLE');

  return (
    <ac-modal
      confirmButton={{
        disabled: isConfirmButtonDisabled,
        content: {
          text: confirmButtonText,
        },
        onClick: (): Promise<void> => handleSubmit(),
        'data-test-selector': ChangePasswordModalTestSelectors.ConfirmButton,
      }}
      cancelButton={{
        onClick: (): void => onClose(),
        'data-test-selector': ChangePasswordModalTestSelectors.CancelButton,
      }}
      modalTitle={t('MENU.MODAL.CHANGE_PASSWORD.TITLE')}
      loading={isLoading}
    >
      <form>
        <div>
          <ac-box>
            <PasswordField
              id="old"
              label={t('MENU.MODAL.CHANGE_PASSWORD.ENTER_OLD_PASSWORD')}
              className="bottom-separator"
              required
              value={oldPassword}
              onChange={setOldPassword}
              validationStatus={state.oldPassword}
              dataTestSelector={
                ChangePasswordModalTestSelectors.OldPasswordField
              }
            />
          </ac-box>
          <ac-text
            weight={TextWeight.semibold}
            uppercase
            size={TextSize.sm}
            class="ac-spacing-bottom-sm"
          >
            {t('MENU.MODAL.CHANGE_PASSWORD.NEW_PASSWORD')}
          </ac-text>
          <ac-box>
            {t('MENU.MODAL.CHANGE_PASSWORD.PASSWORD_REQUIREMENTS.TITLE')}
            <ul className="password-requirements">
              {passwordRequirements.map((req) => (
                <li key={`password-requirement-${req.title}`}>
                  <ac-icon
                    data-test-selector={`${ChangePasswordModalTestSelectors.ValidationStatusIcon}-${req.title}`}
                    icon={req.rule(newPassword) ? IconName.check : IconName.dot}
                    class="ac-spacing-right-sm"
                  />
                  {req.title}
                </li>
              ))}
            </ul>
          </ac-box>
          <ac-box>
            <PasswordField
              id="new"
              label={t('MENU.MODAL.CHANGE_PASSWORD.ENTER_NEW_PASSWORD')}
              required
              value={newPassword}
              onChange={setNewPassword}
              validationStatus={state.newPassword}
              dataTestSelector={
                ChangePasswordModalTestSelectors.NewPasswordField
              }
            />
          </ac-box>
          <ac-box>
            <PasswordField
              id="new-confirm"
              label={t('MENU.MODAL.CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD')}
              required
              value={newPasswordConfirmation}
              onChange={setNewPasswordConfirmation}
              validationStatus={state.newPasswordConfirmation}
              dataTestSelector={
                ChangePasswordModalTestSelectors.ConfirmNewPasswordField
              }
              hideMessageContainer={
                !state.newPasswordConfirmation.length &&
                Boolean(apiErrors.length)
              }
            />
          </ac-box>
          {apiErrors.length ? (
            <ac-flex class="ac-spacing-top-sm">
              <ac-validation-message
                iconName={IconName.alert}
                iconColor={Color.alert}
                label={t('MENU.MODAL.CHANGE_PASSWORD.ERROR.REQUEST_FAILED')}
              />
            </ac-flex>
          ) : undefined}
        </div>
      </form>
    </ac-modal>
  );
};
