import { getRemoteSupportTranslationKey } from 'frontend-container/components/panels/maintenancePanel/features/remoteSupport/translation';

import { PageSectionManifest } from '@ac/react-infrastructure';
import { IconName } from '@ac/web-components';

export const remoteSupportSectionManifest: PageSectionManifest = {
  id: 'maintenance-panel-remote-support-section',
  icon: IconName.cursor,
  title: getRemoteSupportTranslationKey('TITLE'),
};
