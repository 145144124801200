import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';

import './Header.scss';

interface Props {
  titleTranslation?: string;
  customDisplay?: ReactNode;
}

export const Header = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const isMenuV2Enabled = getIsMenuV2Enabled();

  return (
    <div
      className={`container-menu-item-header ${
        isMenuV2Enabled ? ' container-menu-v2-item-header' : ''
      }`}
    >
      {props.titleTranslation && t(props.titleTranslation)}
      {props.customDisplay && props.customDisplay}
    </div>
  );
};
