import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  croLandingPagePathname,
  propertyLandingPagePathname,
} from 'frontend-container/components/LandingPage';
import { profileCenterLandingPagePathname } from 'frontend-container/components/LandingPage/location';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import {
  getConfigurationContexts,
  isConfigurationEnabled,
} from 'frontend-container/components/Menu/components/ConfigurationContext/service';
import { Context } from 'frontend-container/components/Menu/components/Context/index';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import {
  getCroContexts,
  getCroItemById,
  getCroItems,
  isCroEnabled,
} from 'frontend-container/components/Menu/components/CroContext/service';
import {
  getProfileCenterItemById,
  getProfileCentersContexts,
  getProfileCentersItems,
  isProfileCentersEnabled,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import {
  getPropertyContexts,
  getPropertyUnitById,
  getPropertyUnits,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/centralAvailabilityInquiry';
import { configurationMenu } from 'frontend-container/components/Menu/configuration/configuration';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/reservationsLookup';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { getCurrentContextOption } from 'frontend-container/components/Menu/utils/getCurrentContext';
import { selectInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';

import {
  CentralReservationOfficeUserListItemDto,
  ProfileCenterDto,
  UnitAssignmentListItem,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { SessionService } from '@ac/library-utils/dist/services';
import { isEmpty } from '@ac/library-utils/dist/utils';
import { IconName } from '@ac/web-components';

export enum ContextOption {
  property = 'property',
  cro = 'cro',
  profileCenter = 'profile-center',
  configuration = 'configuration',
}

export type ButtonContextOption = {
  id: string;
  label: string;
  icon: IconName;
  onClick: () => void;
  getContext: (
    currentButtonOption?: ButtonContextOption
  ) => Context[] | ButtonContextOption | undefined;
  url: string;
  subMenuElements?: MenuElementItem[];
  unitName?: string;
};

export type ContextOptions = {
  [key in ContextOption]: {
    buttonOption: ButtonContextOption;
    guard: boolean;
  };
};

export type ContextSelectButtonOptions = {
  contextOptions: ContextOptions;
  buttonSelectOptions: ButtonContextOption[];
  currentButtonOption?: ButtonContextOption;
};

type UseContextMenuItems = {
  getContextMenuItems: (
    accessConfiguration?: AllAccessConfiguration
  ) => ContextSelectButtonOptions;
};

type ContextUnit =
  | CentralReservationOfficeUserListItemDto
  | UnitAssignmentListItem
  | ProfileCenterDto
  | undefined;

const getMenuElementItems = (
  menuElements: MenuElement[],
  useMenuElementLabel?: boolean
): MenuElementItem[] => {
  if (useMenuElementLabel) {
    return menuElements.flatMap((element) =>
      element.items.map((item) => ({
        ...item,
        translation: element.translation,
      }))
    );
  }

  return menuElements.flatMap((element) => element.items);
};

const getUnitForContext = (context: ContextOption): ContextUnit => {
  switch (context) {
    case ContextOption.cro: {
      const croId = SessionService.getCentralReservationOfficeId();

      return croId ? getCroItemById(croId) : getCroItems().at(0);
    }
    case ContextOption.property: {
      const propertyId = SessionService.getPropertyId();

      return propertyId
        ? getPropertyUnitById(propertyId)
        : getPropertyUnits().at(0);
    }
    case ContextOption.profileCenter: {
      const profileCenterId = SessionService.getProfileCenterId();

      return profileCenterId
        ? getProfileCenterItemById(profileCenterId)
        : getProfileCentersItems().at(0);
    }
    default: {
      return undefined;
    }
  }
};

const getContextUnitCode = (context: ContextOption): string | undefined => {
  const unit = getUnitForContext(context);
  if (!unit) {
    return;
  }

  return unit.code ?? undefined;
};

export const useContextMenuItems = (): UseContextMenuItems => {
  const { t } = useTranslation();
  const { centralReservationOfficeId, propertyId, profileCenterId } =
    useSessionContextTypeIds();

  const getContextMenuItems = useCallback(
    (
      accessConfiguration?: AllAccessConfiguration
    ): ContextSelectButtonOptions => {
      const croGuard = (): boolean => {
        const croContexts = getCroContexts();
        const isCroSupported = !isEmpty(croContexts) && isCroEnabled();
        const targetCroId = selectInitialContext(
          croContexts,
          centralReservationOfficeId
        );

        if (!isCroSupported || !targetCroId) {
          return false;
        }

        return true;
      };

      const currentContextOption = getCurrentContextOption();

      const croMenuSubItems = (): MenuElementItem[] =>
        accessConfiguration
          ? getMenuElementItems(
              getAllowedMenuItems(
                [centralAvailabilityInquiryMenu, reservationsLookupMenu],
                accessConfiguration
              ),
              true
            )
          : [];

      const configurationSubItemsMenu = (): MenuElementItem[] =>
        accessConfiguration
          ? getMenuElementItems(
              getAllowedMenuItems([configurationMenu], accessConfiguration)
            )
          : [];

      const propertyGuard = (): boolean => {
        const propertyContexts = getPropertyContexts();
        const canSwitchToProperty = propertyContexts.length > 0;
        const targetPropertyId = selectInitialContext(
          propertyContexts,
          propertyId
        );

        if (!canSwitchToProperty || !targetPropertyId) {
          return false;
        }

        return true;
      };

      const profileCenterGuard = (): boolean => {
        const profileCentersContexts = getProfileCentersContexts();
        const targetProfileCenterId = selectInitialContext(
          profileCentersContexts,
          profileCenterId
        );
        const canSwitchToProfileCenters =
          !isEmpty(profileCentersContexts) && isProfileCentersEnabled();

        if (!canSwitchToProfileCenters || !targetProfileCenterId) {
          return false;
        }

        return true;
      };

      const configurationGuard = (): boolean => {
        return isConfigurationEnabled();
      };

      const contextOptions: ContextOptions = {
        [ContextOption.property]: {
          buttonOption: {
            id: ContextOption.property,
            label: t('MENU.CONTEXT.SWITCH_TO_PROPERTY'),
            icon: IconName.propertyZone,
            url: propertyLandingPagePathname,
            unitName: getContextUnitCode(ContextOption.property),
            getContext: (): Context[] => getPropertyContexts(),
            onClick: (): void => navigateToPage(propertyLandingPagePathname),
          },
          guard: propertyGuard(),
        },
        [ContextOption.profileCenter]: {
          buttonOption: {
            id: ContextOption.profileCenter,
            label: t('MENU.CONTEXT.SWITCH_TO_PROFILE_CENTER'),
            icon: IconName.profileDetails,
            unitName: getContextUnitCode(ContextOption.profileCenter),
            url: profileCenterLandingPagePathname,
            getContext: (): Context[] => getProfileCentersContexts(),
            onClick: (): void =>
              navigateToPage(profileCenterLandingPagePathname),
          },
          guard: profileCenterGuard(),
        },
        [ContextOption.cro]: {
          buttonOption: {
            id: ContextOption.cro,
            label: t('MENU.CONTEXT.SWITCH_TO_CRO'),
            icon: IconName.cro,
            url: croLandingPagePathname,
            unitName: getContextUnitCode(ContextOption.cro),
            onClick: (): void => navigateToPage(croLandingPagePathname),
            getContext: (): Context[] => getCroContexts(),
            subMenuElements: croMenuSubItems(),
          },
          guard: croGuard(),
        },
        [ContextOption.configuration]: {
          buttonOption: {
            id: ContextOption.configuration,
            label: t('MENU.CONTEXT.SWITCH_TO_CONFIGURATION'),
            icon: IconName.settings,
            url: acConfig.newFrontendUrls.configurationv2,
            getContext: (
              currentButtonOption?: ButtonContextOption
            ): Context[] | ButtonContextOption | undefined =>
              getConfigurationContexts(currentButtonOption),
            onClick: (): void =>
              navigateToPage(acConfig.newFrontendUrls.configurationv2),
            subMenuElements: configurationSubItemsMenu(),
          },
          guard: configurationGuard() || configurationSubItemsMenu().length > 0,
        },
      };

      const buttonSelectOptions = Object.values(contextOptions).reduce<
        ButtonContextOption[]
      >((acc, curr) => {
        if (curr.guard) {
          return [...acc, { ...curr.buttonOption }];
        }

        return acc;
      }, []);

      return {
        contextOptions,
        buttonSelectOptions,
        currentButtonOption:
          currentContextOption &&
          contextOptions[currentContextOption].buttonOption,
      };
    },
    [centralReservationOfficeId, profileCenterId, propertyId, t]
  );

  return {
    getContextMenuItems,
  };
};
