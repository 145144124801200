import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { GlobalSearchModalContentBody } from 'frontend-container/components/Menu/components/GlobalSearchModal/components/GlobalSearchModalContentBody';
import { userPreferencesService } from 'frontend-container/components/Menu/components/User/UserPreferences/service';
import { getInitialValues } from 'frontend-container/components/Menu/components/User/UserPreferences/UserPreferencesModal/values/getInitialValues';
import { useFavoritesContext } from 'frontend-container/components/Menu/store/favorites/context';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { useContainerToast } from '@ac/react-infrastructure';
import { TransparentContainerTheme } from '@ac/web-components';

import './GlobalSearchModal.scss';

interface Props {
  selectedProperty: Context | undefined;
  selectedCro: Context | undefined;
  selectedProfileCenter: Context | undefined;
  mainApplicationMenu: MenuElement[];
  onCloseCallback: () => void;
}

export const GlobalSearchModal = ({
  selectedCro,
  selectedProfileCenter,
  selectedProperty,
  mainApplicationMenu,
  onCloseCallback,
}: Props): JSX.Element | null => {
  const { favoritesItemsIds } = useFavoritesContext();
  const { showError } = useContainerToast();
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState(false);

  const onCloseAndSaveCallback = async (): Promise<void> => {
    onCloseCallback?.();

    if (isDirty) {
      const allPreferences = getInitialValues();
      const newPreferences = {
        ...allPreferences,
        mainMenuSettings: {
          showFavoriteOnly:
            allPreferences.mainMenuSettings?.showFavoriteOnly ?? false,
          favoriteMenuItems: favoritesItemsIds,
        },
      };

      const hasSucceed = await userPreferencesService.saveUserPreferences(
        newPreferences,
        false
      );

      if (!hasSucceed) {
        showError(t('MENU.MAIN_MENU.ERRORS.NO_FAVORITES_ADDED'));
      }

      setIsDirty(false);
    }
  };

  const handleSetIsDirty = useCallback((_isDirty: boolean): void => {
    setIsDirty(_isDirty);
  }, []);

  return (
    <ac-transparent-container
      theme={TransparentContainerTheme.dark}
      onClickHandler={onCloseAndSaveCallback}
      class="global-search-modal"
    >
      <ac-click-outside>
        <GlobalSearchModalContentBody
          mainApplicationMenu={mainApplicationMenu}
          selectedProperty={selectedProperty}
          selectedCro={selectedCro}
          selectedProfileCenter={selectedProfileCenter}
          onCloseAndSaveCallback={onCloseAndSaveCallback}
          handleSetIsDirty={handleSetIsDirty}
        />
      </ac-click-outside>
    </ac-transparent-container>
  );
};
