import { FavoritesListContext } from 'frontend-container/components/Menu/store/favorites/context';
import { useFavoriteListStore } from 'frontend-container/components/Menu/store/favorites/store';

import { PropsWithChildren } from '@ac/react-infrastructure';

export const FavoriteListContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useFavoriteListStore();

  return (
    <FavoritesListContext.Provider value={store}>
      {props.children}
    </FavoritesListContext.Provider>
  );
};
