import { useMemo } from 'react';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';

interface Props {
  globalSearchItems: GlobalSearchMenuElementItem[];
  favoritesItemsIds: string[];
}

export interface FavoriteItems {
  favoriteItems: GlobalSearchMenuElementItem[];
}

export const useFavoriteItems = ({
  globalSearchItems,
  favoritesItemsIds,
}: Props): FavoriteItems => {
  const favoriteItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    if (!favoritesItemsIds.length) {
      return [];
    }

    return globalSearchItems
      .filter((allowedItem) => favoritesItemsIds.includes(allowedItem.id))
      .map((el) => ({ ...el, adjustedId: `${el.id}-favorite` }));
  }, [globalSearchItems, favoritesItemsIds]);

  return { favoriteItems };
};
