import { getPageIdentifier } from 'frontend-container/utils/activityTracker/getPageIdentifier';
import { ActivityTrackerCommand } from 'frontend-container/utils/activityTracker/types';
import { getDefaultWorkstationId } from 'frontend-container/utils/defaultWorkstation';
import { addRefreshTokenListener } from 'frontend-container/utils/loginService/loginServiceInitializers';
import debounce from 'lodash.debounce';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

const worker = new Worker(
  /* webpackChunkName: "activityTrackerWorker" */ new URL(
    './activityTracker.worker.ts',
    import.meta.url
  )
);

export const startNewPageActivityTracker = (pageIdentifier: string): void => {
  debouncedSendNewPage.cancel();

  sendNewPageActivityTracker(pageIdentifier);
};

const sendNewPageActivityTracker = (pageIdentifier: string): void => {
  worker.postMessage({
    command: ActivityTrackerCommand.NewPage,
    apiUrl: acConfig.apiUrl,
    pageIdentifier,
    workstationId: getDefaultWorkstationId(),
    propertyId: SessionService.getPropertyId(),
    tenantId: LoginService.authData()?.tenantId,
    accessToken: LoginService.accessToken(),
  });
};

export const stopPageActivityTracker = (pageIdentifier: string): void => {
  worker.postMessage({
    command: ActivityTrackerCommand.Stop,
    pageIdentifier,
  });
};

export const updateActivityTrackerWorkerData = (): void => {
  worker.postMessage({
    command: ActivityTrackerCommand.RequestInfo,
    apiUrl: acConfig.apiUrl,
    workstationId: getDefaultWorkstationId(),
    propertyId: SessionService.getPropertyId(),
    tenantId: LoginService.authData()?.tenantId,
    accessToken: LoginService.accessToken(),
  });
};

const debouncedSendNewPage = debounce(sendNewPageActivityTracker, 500);

const initActivityTracker = (): void => {
  addRefreshTokenListener(updateActivityTrackerWorkerData);

  window.addEventListener('single-spa:routing-event', () => {
    debouncedSendNewPage(getPageIdentifier());
  });
  window.addEventListener('pagehide', () => {
    stopPageActivityTracker(getPageIdentifier());
  });
};

export { initActivityTracker };
