import { useMemo } from 'react';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import {
  getCroContexts,
  isCroEnabled,
} from 'frontend-container/components/Menu/components/CroContext/service';
import {
  getProfileCentersContexts,
  isProfileCentersEnabled,
} from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/centralAvailabilityInquiry';
import { profileCenterMenu } from 'frontend-container/components/Menu/configuration/profileCenters';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/reservationsLookup';
import { useContextsContext } from 'frontend-container/components/Menu/store/context';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

export type RightMenuItem = {
  selectedContextCode?: string;
  menuElements?: MenuElement[];
  withoutHeader?: boolean;
  isSeparator?: boolean;
};

export const useRightColumnConfig = (
  allAccessConfiguration: AllAccessConfiguration | undefined,
  selectedCro: Context | undefined,
  selectedProfileCenter: Context | undefined
): RightMenuItem[] => {
  const { allContextLists } = useContextsContext();

  const configuration = allContextLists.get('configuration') as
    | ButtonContextOption
    | undefined;

  const configurationMainItems = configuration?.subMenuElements?.filter(
    (configurationSubElement) =>
      configurationSubElement.link.startsWith(
        `${acConfig.newFrontendUrls.configurationv2}/customers`
      )
  );

  const configurationSecondaryItems = configuration?.subMenuElements?.filter(
    (configurationSubElement) =>
      !configurationSubElement.link.startsWith(
        `${acConfig.newFrontendUrls.configurationv2}/customers`
      )
  );

  const allowedProfileCenter = useMemo(() => {
    if (allAccessConfiguration) {
      return getAllowedMenuItems([profileCenterMenu], allAccessConfiguration);
    }

    return [];
  }, [allAccessConfiguration]);
  const croContexts = useMemo(() => getCroContexts(), []);
  const profileCentersContexts = useMemo(() => getProfileCentersContexts(), []);
  const centralAvailabilityInquiryMenuItem = useMemo(() => {
    return {
      ...centralAvailabilityInquiryMenu.items[0],
      translation: `${centralAvailabilityInquiryMenu.items[0].translation}`,
    };
  }, []);
  const reservationsLookupMenuItem = useMemo(() => {
    return {
      ...reservationsLookupMenu.items[0],
      translation: `${reservationsLookupMenu.items[0].translation}`,
    };
  }, []);

  return useMemo(() => {
    const menuElements: RightMenuItem[] = [];

    if (
      isProfileCentersEnabled() &&
      !!allowedProfileCenter?.length &&
      !!profileCentersContexts?.length
    ) {
      menuElements.push({
        selectedContextCode: selectedProfileCenter?.code,
        menuElements: [
          {
            ...allowedProfileCenter[0],
            icon: IconName.perPax,
            items: allowedProfileCenter[0].items,
          },
        ],
      });
    }

    if (isCroEnabled() && !!croContexts?.length) {
      menuElements.push({
        selectedContextCode: selectedCro?.code,
        menuElements: [
          {
            translation: 'MENU.CRO.TITLE',
            icon: IconName.cro,
            id: 'menu-cro',
            items: [
              centralAvailabilityInquiryMenuItem,
              reservationsLookupMenuItem,
            ],
          },
        ],
      });
    }

    if (configuration && !!configurationMainItems?.length) {
      menuElements.push({
        menuElements: [
          {
            translation: configuration.label,
            id: configuration.id,
            icon: IconName.settings,
            items: configurationMainItems ?? [],
          },
        ],
      });
    }

    if (
      !!configurationMainItems?.length &&
      !!configurationSecondaryItems?.length
    ) {
      menuElements.push({
        isSeparator: true,
      });
    }

    if (
      configuration &&
      !!configurationMainItems?.length &&
      !!configurationSecondaryItems?.length
    ) {
      menuElements.push({
        withoutHeader: true,
        menuElements: [
          {
            translation: configuration.label,
            id: configuration.id,
            icon: IconName.settings,
            items: configurationSecondaryItems ?? [],
          },
        ],
      });
    }

    return menuElements;
  }, [
    centralAvailabilityInquiryMenuItem,
    reservationsLookupMenuItem,
    allowedProfileCenter,
    profileCentersContexts,
    selectedProfileCenter,
    selectedCro,
    croContexts,
    configuration,
    configurationMainItems,
    configurationSecondaryItems,
  ]);
};
