import { Context } from 'frontend-container/components/Menu/components/Context';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { getCroUrlForNewRegion } from 'frontend-container/utils/region/getUrlForNewRegion';

import {
  getCurrentRegionCode,
  getGlobalRegionCode,
} from '@ac/library-utils/dist/utils/multi-region';

/**
 * Note: Use this method only on the menu selector for CRO changes.
 * If user change CRO we always want to redirect to appropriate region.
 */
export const redirectOnCroSelectIfContextRegionDiffer = (
  context: Context
): boolean => {
  const region = findRegionData(context.regionCode ?? getGlobalRegionCode());

  const regionCode = region?.code ?? '';
  const domain = region?.domain ?? '';
  const currentRegionCode = getCurrentRegionCode();

  if (currentRegionCode && context?.regionCode !== currentRegionCode) {
    window.location.replace(getCroUrlForNewRegion(regionCode, domain, context));

    return true;
  }

  return false;
};
