import { isCroCreateOrEditReservationPathname } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';

import { DeploymentRegionType } from '@ac/library-api';

/**
 * Note: We shouldn't redirect App to default CRO's region for reservation pages under CRO modules because we need to display
 * reservation view correctly. Property might be deployed in a different region than CRO was deployed.
 * Thus, we need to fetch property data from appropriate region. On the remaining CRO pages, we want to redirect
 * user to the deployed region that we assume is the closest to recipients (in terms of location).
 */
export const shouldRedirectToProperCroRegion = (
  deploymentRegionType?: DeploymentRegionType
): boolean => {
  if (
    deploymentRegionType === DeploymentRegionType.Global &&
    isCroCreateOrEditReservationPathname()
  ) {
    return false;
  }

  return true;
};
