import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context';
import { isGlobalRegion } from 'frontend-container/components/Menu/utils/isGlobalRegion';
import { isModuleEditableInGlobalRegionOnly } from 'frontend-container/components/Menu/utils/isModuleEditableInGlobalRegionOnly';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { redirectToRegion } from 'frontend-container/utils/region/redirectToRegion';
import { shouldRedirectToProperCroRegion } from 'frontend-container/utils/region/shouldRedirectToProperCroRegion';

import {
  getCurrentRegionCode,
  getGlobalRegionCode,
} from '@ac/library-utils/dist/utils/multi-region';

export const redirectToProperRegionIfNecessary = (
  selectedContext: Context
): boolean => {
  if (
    selectedContext.type === ContextType.CRO &&
    !shouldRedirectToProperCroRegion(selectedContext.deploymentRegionType)
  ) {
    return false;
  }

  const currentRegionCode = getCurrentRegionCode();

  if (
    isGlobalRegion(currentRegionCode) &&
    isModuleEditableInGlobalRegionOnly()
  ) {
    return false;
  }

  const contextRegion = findRegionData(
    selectedContext.regionCode ?? getGlobalRegionCode()
  );

  if (
    contextRegion &&
    currentRegionCode &&
    contextRegion.code.toLowerCase() !== currentRegionCode.toLowerCase()
  ) {
    return redirectToRegion(
      contextRegion.code ?? '',
      contextRegion.domain ?? '',
      selectedContext
    );
  }

  return false;
};
